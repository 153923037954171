import {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

import {ButtonIcon, CustomTable, downloadUtils, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {BonaFideNavBar} from '../../components/bonaFides';
import {bonaFideLetterApi} from '../../api';
import {BonaFideLetterBatchLite} from '../../types/bonaFide';
import {formatDate} from '../../util';
import * as messages from '../../messages';
import {LetterBatchStatusBadge} from '../../components/shared';

const BonaFideLetterBatches = () => {
  const [bonaFideLetterBatches, setBonaFideLetterBatches] = useState<BonaFideLetterBatchLite[]>([]);
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});
  const {showErrorAlert} = useAlerts();

  const loadBonaFideLetterBatches = useCallback(async () => {
    try {
      setLoadingState({loading: true, loadError: false, processing: false});
      const bonaFideLetterBatches = await bonaFideLetterApi.findBatches();
      setBonaFideLetterBatches(bonaFideLetterBatches);
      setLoadingState({loading: false, loadError: false, processing: false});

      // Refresh every 20 seconds
      if (bonaFideLetterBatches.filter(bonaFideLetterBatches => bonaFideLetterBatches.status === 'PROCESSING').length > 0) {
        setTimeout(() => loadBonaFideLetterBatches().then(), 1000 * 20);
      }
    } catch (e) {
      setLoadingState({loading: false, loadError: true, processing: false});
      showErrorAlert(messages.API_FAILURE);
    }
  }, [
    showErrorAlert
  ]);

  useEffect(() => {
    void loadBonaFideLetterBatches();
  }, [
    loadBonaFideLetterBatches
  ]);

  const handleDownloadFile = useCallback(async (id: number) => {
    try {
      await downloadUtils.downloadFile(bonaFideLetterApi.getBatchSignedUrl(id));
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_DOWNLOAD_FAILURE);
    }
  }, [
    showErrorAlert
  ]);

  const tableProps = useMemo(() => ({
    items: bonaFideLetterBatches,
    headers: [
      {title: 'Letter Batch ID', className: 'text-center', sortKey: 'fileNumber'},
      {title: 'Created', className: 'text-center', sortKey: 'createdAt'},
      {title: '# Bona Fides', className: 'text-center'},
      {title: 'Status', className: 'text-center', sortKey: 'status'},
      {title: 'View', className: 'text-center'},
      {title: 'Download', className: 'text-center'}
    ],
    noResultsMessage: 'No Bona Fide letter batches.',
    paginatorConfig: {
      perPage: 100
    },
    initialSort: {
      sortKey: 'fileNumber',
      direction: 'desc' as const
    },
    renderRow: (bonaFideLetterBatch: BonaFideLetterBatchLite) => {
      return <tr key={bonaFideLetterBatch.id}>
        <td className="text-center align-middle">
          {bonaFideLetterBatch.fileNumber}
        </td>
        <td className="text-center align-middle">
          {formatDate(bonaFideLetterBatch.createdAt)}
        </td>
        <td className="text-center align-middle">
          {bonaFideLetterBatch.expectedItemCount}
        </td>
        <td className="text-center align-middle">
          <LetterBatchStatusBadge status={bonaFideLetterBatch.status}/>
        </td>
        <td className="text-center align-middle">
          <Link to={`/bona-fides/letters/batches/${bonaFideLetterBatch.id}`}>
            View
          </Link>
        </td>
        <td className="text-center text-primary">
          <ButtonIcon icon="paperclip"
                      disabled={bonaFideLetterBatch.status !== 'ACTIVE'}
                      ariaLabel={`Download batch ${bonaFideLetterBatch.id}`}
                      title={`Download batch ${bonaFideLetterBatch.id}`}
                      onClick={() => handleDownloadFile(bonaFideLetterBatch.id)}/>
        </td>
      </tr>;
    }
  }), [
    bonaFideLetterBatches,
    handleDownloadFile
  ]);

  return <Container fluid>
    <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <BonaFideNavBar/>
      </Col>
    </Row>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && <Row>
      <Col>
        <Card>
          <CardHeader>Voucher Letter Batches</CardHeader>
          <CustomTable {...tableProps}/>
        </Card>
      </Col>
    </Row>}
  </Container>;
};

export default BonaFideLetterBatches;