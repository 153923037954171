import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Modal, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {interestWaiverErrorInformationSchema} from '../../schema/interestWaiver';
import {InterestWaiverResponse} from '../../types/interestWaiver';
import {InterestWaiverErrorRequest, InterestWaiverRequest} from '../../types/request/interestWaiver';

type Props = {
  interestWaiver: InterestWaiverResponse
  onSave: (interestWaiverRequest: InterestWaiverRequest) => void
}

const InterestWaiverErrorInformationCard = ({
                                              interestWaiver,
                                              onSave
                                            }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (interestWaiverErrorRequest: InterestWaiverErrorRequest) => {
    const interestWaiverRequest = {
      ...interestWaiver,
      ...interestWaiverErrorRequest
    } as InterestWaiverRequest;

    await onSave(interestWaiverRequest);
    setModalIsOpen(false);
  }, [
    onSave,
    interestWaiver
  ]);

  const initialValues: InterestWaiverErrorRequest = useMemo(() => ({
    errorType: interestWaiver.errorType,
    otherExplanation: interestWaiver.otherExplanation
  }), [
    interestWaiver
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Error Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-25">Error Type</th>
            <th className="w-75">Other Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{interestWaiver.errorType}&nbsp;</td>
            <td>{interestWaiver.otherExplanation}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Error Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={interestWaiverErrorInformationSchema}>
        {(formikProps) => (<>
          <CardBody>
            <Row>
              <Col>
                <FormikSelect name="errorType"
                              autoFocus
                              labelText="Error Type">
                  <option value="">Select</option>
                  {['CLASSIFICATION ERROR', 'FAILURE TO RESCIND', 'OTHER'].map(errorType =>
                    <option value={errorType} key={errorType}>{errorType}</option>
                  )}
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="otherExplanation"
                             type="textarea"
                             formGroupClass="mb-0"
                             aria-required={formikProps.values.errorType === 'OTHER'}
                             disabled={formikProps.values.errorType !== 'OTHER'}
                             labelText="Explanation"/>
              </Col>
            </Row>
          </CardBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default InterestWaiverErrorInformationCard;