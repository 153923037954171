import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Modal, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikFileInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {InterestWaiverResponse} from '../../types/interestWaiver';
import {interestWaiverSupervisorDeterminationSchema} from '../../schema/interestWaiver';

type Props = {
  interestWaiver: InterestWaiverResponse
  onDownloadFile: (path: string) => void
  onSave: (formData: FormData) => void
}

type Values = {
  supervisorDetermination: string
  supervisorDeterminationExplanation: File | null
}

const InterestWaiverSupervisorDeterminationCard = ({
                                                     interestWaiver,
                                                     onDownloadFile,
                                                     onSave
                                                   }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (values: Values,
                                        formikHelpers: FormikHelpers<Values>) => {
    const formData = new FormData();
    formData.set('supervisorDetermination', values.supervisorDetermination);

    if (values.supervisorDeterminationExplanation !== null) {
      formData.set('supervisorDeterminationExplanation', values.supervisorDeterminationExplanation as File);
    }

    await onSave(formData);
    formikHelpers.resetForm();
    setModalIsOpen(false);
  }, [
    onSave
  ]);

  const initialValues: Values = useMemo(() => ({
    supervisorDetermination: interestWaiver.supervisorDetermination,
    supervisorDeterminationExplanation: null
  }), [
    interestWaiver
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Supervisor Determination
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th scope="row" className="w-50">Determination</th>
            <th scope="row" className="w-50">Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{interestWaiver.supervisorDetermination}&nbsp;</td>
            <td>
              {interestWaiver.supervisorDeterminationExplanation &&
                <Button color="link"
                        onClick={() => onDownloadFile(interestWaiver.supervisorDeterminationExplanation.downloadLink)}>
                  {interestWaiver.supervisorDeterminationExplanation.filename}
                </Button>}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="md">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Supervisor Determination
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={interestWaiverSupervisorDeterminationSchema}>
        {(formikProps) => (<>
          <CardBody>
            <Row>
              <Col>
                <FormikSelect name="supervisorDetermination"
                              autoFocus
                              labelText="Determination">
                  <option value="">Select</option>
                  <option value="APPROVE" key="APPROVE">APPROVE</option>
                  <option value="DENY" key="DENY">DENY</option>
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikFileInput name="supervisorDeterminationExplanation"
                                 labelText="Explanation"
                                 formGroupClass="mb-0"/>
              </Col>
            </Row>
          </CardBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default InterestWaiverSupervisorDeterminationCard;