import {useCallback, useMemo} from 'react';
import {Button, Col, Modal, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDecimalInput, FormikInput, FormikNumberInput, FormikSelect} from '@reasoncorp/kyber-js';

import {bonaFideDenialYearSchema} from '../../schema/bonaFide';
import {bonaFideCalculationUtils, formatMoney, getAvailableBonaFideDenialYears} from '../../util';
import {BonaFideDenialYear} from '../../types/bonaFide';
import * as messages from '../../messages';
import BonaFideDenialYearFormRow from './BonaFideDenialYearFormRow';

type Props = {
  isOpen: boolean
  onSubmit: (bonaFideDenialYear: BonaFideDenialYear) => void
  onSubmitAndAddAnother: (bonaFideDenialYear: BonaFideDenialYear) => void
  onCancel: () => void
  selectedDenialYear: BonaFideDenialYear | undefined
  denialYears: BonaFideDenialYear[]
}

const BonaFideDenialYearModal = ({
                                   isOpen,
                                   onCancel,
                                   onSubmit,
                                   onSubmitAndAddAnother,
                                   selectedDenialYear,
                                   denialYears
                                 }: Props) => {
  const otherDenialYears = useMemo(() => denialYears.filter((denialYear) => {
    return selectedDenialYear === undefined || (selectedDenialYear && selectedDenialYear.id && selectedDenialYear.id !== denialYear.id);
  }), [
    selectedDenialYear,
    denialYears
  ]);

  const initialValues = useMemo(() => ({
    id: selectedDenialYear?.id ?? undefined,
    year: selectedDenialYear?.year ?? '',
    millageRate: selectedDenialYear?.millageRate ?? '',
    taxableValue: selectedDenialYear?.taxableValue ?? '',
    billedOn: selectedDenialYear?.billedOn ?? '',
    exemptionPenaltyAmount: selectedDenialYear?.exemptionPenaltyAmount ?? '',
    billAdjustmentAmount: selectedDenialYear?.billAdjustmentAmount ?? '',
    taxLevySeason: selectedDenialYear?.taxLevySeason ?? null,
    taxDue: selectedDenialYear?.taxDue ?? 0,
    interestDue: selectedDenialYear?.interestDue ?? 0,
    totalDue: selectedDenialYear?.totalDue ?? 0,
    deniedBy: selectedDenialYear?.deniedBy ?? null,
    status: selectedDenialYear?.status ?? null,
    shouldIssueBill: (selectedDenialYear?.status ?? '') === 'ISSUE_BILL'
  }), [
    selectedDenialYear
  ]);

  const isUniquePerYearAndSeason = useCallback((values: BonaFideDenialYear) => {
    return otherDenialYears.filter(denialYear => {
      const hasSameYearAndTaxLevySeason = denialYear.year === Number(values.year) &&
        denialYear.taxLevySeason === values.taxLevySeason;

      const hasCombinedInYear = denialYear.year === Number(values.year) && (
        denialYear.taxLevySeason === 'COMBINED' ||
        values.taxLevySeason === 'COMBINED');

      return hasSameYearAndTaxLevySeason || hasCombinedInYear;
    }).length === 0;
  }, [
    otherDenialYears
  ]);

  return <Modal isOpen={isOpen}
                toggle={() => onCancel()}
                autoFocus={false}
                returnFocusAfterClose={true}
                size="lg">
    <ModalHeader toggle={() => onCancel()}
                 tag="h2"
                 className="h5 mb-0">
      {selectedDenialYear?.id ? 'Edit' : 'Add New'} Tax Due Billing Details
    </ModalHeader>
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validationSchema={bonaFideDenialYearSchema}>
      {(formikProps) => (<>
        <div className="pl-3 pr-3">
          <BonaFideDenialYearFormRow header="Year">
            <FormikSelect autoFocus
                          name="year"
                          aria-required
                          formGroupClass="mb-0"
                          ariaLabel="Year">
              <option key="" value="">Select</option>
              {getAvailableBonaFideDenialYears.map(year => <option value={year} key={year}>{year}</option>)}
            </FormikSelect>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Denied By">
            <FormikSelect name="deniedBy"
                          formGroupClass="mb-0"
                          ariaLabel="Denied By">
              <option value="">Select</option>
              <option value="ASSESSOR" key="ASSESSOR">Assessor</option>
              <option value="COUNTY" key="COUNTY">County</option>
              <option value="TREASURY" key="TREASURY">Treasury</option>
            </FormikSelect>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Tax Levy">
            <FormikSelect name="taxLevySeason"
                          aria-required
                          formGroupClass="mb-0"
                          ariaLabel="Tax Levy">
              <option key="" value="">Select</option>
              <option key="SUMMER" value="SUMMER">Summer</option>
              <option key="WINTER" value="WINTER">Winter</option>
              <option key="COMBINED" value="COMBINED">Combined</option>
            </FormikSelect>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Status">
            <FormikSelect name="status"
                          formGroupClass="mb-0"
                          required
                          aria-required
                          ariaLabel="Status">
              <option key="" value="">Select</option>
              <option key="ISSUE_BILL" value="ISSUE_BILL">Issue Bill</option>
              <option key="CANCELED" value="CANCELED">Canceled</option>
            </FormikSelect>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Billing Date: Request Received Date">
            <FormikInput name="billedOn"
                         type="date"
                         aria-required
                         formGroupClass="mb-0"
                         disableFloatingLabel
                         ariaLabel="Billing Date: Request Received Date"/>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Millage Rate">
            <FormikDecimalInput name="millageRate"
                                decimalPlaces="4"
                                integerPlaces="2"
                                className="text-right"
                                formGroupClass="mb-0"
                                aria-required
                                disableFloatingLabel={true}
                                ariaLabel="Millage Rate"/>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Taxable Value">
            <FormikNumberInput name="taxableValue"
                               formGroupClass="mb-0"
                               aria-required
                               maxLength={15}
                               className="text-right"
                               disableFloatingLabel={true}
                               ariaLabel="Taxable Value"/>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Substantially Similar Exemption Penalty (+/-)">
            <FormikNumberInput name="exemptionPenaltyAmount"
                               className="text-right"
                               formGroupClass="mb-0"
                               maxLength={7}
                               disableFloatingLabel={true}
                               ariaLabel="Substantially Similar Exemption Penalty"/>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Bill Adjustment (+/-)">
            <FormikNumberInput name="billAdjustmentAmount"
                               className="text-right"
                               maxLength={16}
                               formGroupClass="mb-0"
                               disableFloatingLabel={true}
                               ariaLabel="Bill Adjustment"/>
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Tax Due"
                                     childColumnClassName="text-right">
            {formatMoney(bonaFideCalculationUtils.displayTaxDue(formikProps.values))}
          </BonaFideDenialYearFormRow>

          <BonaFideDenialYearFormRow header="Interest Due"
                                     childColumnClassName="text-right">
            {formatMoney(bonaFideCalculationUtils.displayInterestDue(formikProps.values))}
          </BonaFideDenialYearFormRow>
        </div>
        <Row className="ml-0 mr-0 pt-3 pb-3 font-weight-bold text-danger BonaFideDenialYearTotalRow">
          {!isUniquePerYearAndSeason(formikProps.values) &&
            <span className="ml-3">{messages.CONFLICTING_TAX_LEVY}
          </span>}
          <Col className="mr-2 text-right">
            <span>Total Due: {formatMoney(bonaFideCalculationUtils.calculateDenialYearTotal(formikProps.values))}</span>
          </Col>
        </Row>
        <ModalFooter className="pr-0 pl-0">
          <Row className="w-100 d-flex m-0">
            <Col className="col-6">
              <Button color="primary"
                      className="mr-2"
                      disabled={formikProps.isSubmitting || !formikProps.isValid || !isUniquePerYearAndSeason(formikProps.values)}
                      onClick={() => onSubmitAndAddAnother(formikProps.values)}>
                Save & Add Another Year
              </Button>
            </Col>
            <Col className="col-6 d-flex justify-content-end">
              <Button color="success"
                      className="mr-2"
                      disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid || !isUniquePerYearAndSeason(formikProps.values)}
                      onClick={formikProps.submitForm}>
                Save
              </Button>
              <Button color="secondary"
                      onClick={() => onCancel()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </>)}
    </Formik>
  </Modal>;
};

export default BonaFideDenialYearModal;