import {DenialLetterBatchDto, DenialLetterBatchLite, DenialLetterDto} from '../types/denial';
import {DenialLetterBatchRequest} from '../types/request/denial';
import {proServApi} from './apiUtils';

export const findBatches = (): Promise<DenialLetterBatchLite[]> => proServApi.getWithJsonResponse(
  `/denials/letters/batches`
);

export const findBatch = (id: number): Promise<DenialLetterBatchDto> => proServApi.getWithJsonResponse(
  `/denials/letters/batches/${id}`
);

export const createBatch = (denialLetterBatchRequest: DenialLetterBatchRequest): Promise<DenialLetterBatchDto> => proServApi.postWithJsonResponse(
  `/denials/letters/batches`,
  {
    body: JSON.stringify(denialLetterBatchRequest)
  }
);

export const getSignedUrl = (id: number): Promise<string> => proServApi.getWithTextResponse(
  `/denials/letters/${id}/signed-url`
);

export const getBatchSignedUrl = (id: number): Promise<string> => proServApi.getWithTextResponse(
  `/denials/letters/batches/${id}/signed-url`
);

export const deleteLetter = (id: number): Promise<string> => proServApi.deleteWithTextResponse(
  `/denials/letters/${id}`
);

export const deleteBatch = (id: number): Promise<string> => proServApi.deleteWithTextResponse(
  `/denials/letters/batches/${id}`
);

export const recreate = (id: number): Promise<DenialLetterDto> => proServApi.putWithJsonResponse(
  `/denials/letters/${id}`
);

export const recreateDenialLetterBatch = (id: number): Promise<DenialLetterBatchDto> => proServApi.putWithJsonResponse(
  `/denials/letters/batches/${id}`
);