import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';

import {FormikInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {QualifiedErrorResponse} from '../../types/qualifiedError';
import {Formik} from 'formik';
import {qualifiedErrorCertificationSchema} from '../../schema/qualifiedError';
import {QualifiedErrorRequest} from '../../types/request/qualifiedError';

type Props = {
  qualifiedError: QualifiedErrorResponse
  onSave: (qualifiedErrorRequest: QualifiedErrorRequest) => void
}

type Values = {
  certificationErrorType?: string
  certificationErrorExplanation?: string
}

const QualifiedErrorCertificationCard = ({
                                           qualifiedError,
                                           onSave
                                         }: Props) => {
  const {permissions} = useUserContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleOpen = useCallback(() => setModalIsOpen(!modalIsOpen), [modalIsOpen]);

  const handleSave = useCallback(async (values: Values) => {
    const qualifiedErrorRequest = {
      ...qualifiedError,
      ...values
    } as QualifiedErrorRequest;

    await onSave(qualifiedErrorRequest);
    setModalIsOpen(false);
  }, [
    qualifiedError,
    onSave
  ]);

  const initialValues = useMemo(() => ({
    certificationErrorType: qualifiedError?.certificationErrorType,
    certificationErrorExplanation: qualifiedError?.certificationErrorExplanation ?? ''
  }), [
    qualifiedError
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Certification
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-25">Error Type</th>
            <th className="w-75">Other Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{qualifiedError.certificationErrorType}&nbsp;</td>
            <td>{qualifiedError.certificationErrorExplanation}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={toggleOpen}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="md">
      <ModalHeader toggle={toggleOpen}
                   tag="h2"
                   className="h5">
        Edit Certification Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={qualifiedErrorCertificationSchema}>
        {(formikProps) => (<Form onSubmit={formikProps.submitForm} autoComplete="off">
          <ModalBody>
            <Row>
              <Col>
                <FormikSelect autoFocus
                              name="certificationErrorType"
                              labelText="Error Type">
                  <option value="">Select</option>
                  {['CLERICAL ERROR', 'MUTUAL MISTAKE OF FACT', 'OTHER'].map(errorType =>
                    <option value={errorType} key={errorType}>{errorType}</option>
                  )}
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="certificationErrorExplanation"
                             type="textarea"
                             formGroupClass="mb-0"
                             aria-required={formikProps.values.certificationErrorType === 'OTHER'}
                             disabled={formikProps.values.certificationErrorType !== 'OTHER'}
                             labelText="Explanation"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={toggleOpen}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>)}
      </Formik>
    </Modal>
  </>;
};

export default QualifiedErrorCertificationCard;