import {useMemo} from 'react';

import {FormikSelect, LocalUnit} from '@reasoncorp/kyber-js';
import {useCollections} from '../../hooks';

type Props = {
  name: string
  countyId: number | null
}

const LocalUnitSelect = ({
                           name,
                           countyId
                         }: Props) => {
  const {filterLocalUnitsByCountyId} = useCollections();

  const renderLocalUnitOption = useMemo(() => (localUnit: LocalUnit) => {
    return <option value={localUnit.id} key={localUnit.id}>
      {localUnit.displayNameWithType}
    </option>;
  }, []);

  return (
    <FormikSelect name={name}
                  disabled={countyId === null}
                  labelText="City/Township">
      <option value="">Select</option>
      {filterLocalUnitsByCountyId(countyId).map(renderLocalUnitOption)}
    </FormikSelect>
  );
};

export default LocalUnitSelect;
