import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikProps, FormikValues} from 'formik';
import {Link} from 'react-router-dom';

import {
  CustomTable,
  FormikDatePicker,
  FormikInput,
  ProgressIndicator,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {qualifiedErrorApi} from '../api';
import * as messages from '../messages';
import {QualifiedErrorSearchResponse} from '../types/qualifiedError';
import {QualifiedErrorSearchRequest} from '../types/request/qualifiedError';
import {qualifiedErrorSearchSchema} from '../schema/qualifiedError';
import {formatDate} from '../util';
import {QualifiedErrorCreateModal} from '../components/qualifiedErrors';

const QualifiedErrors = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [qualifiedErrors, setQualifiedErrors] = useState<QualifiedErrorSearchResponse[]>([]);
  const {showErrorAlert} = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSearch = useCallback(async (qualifiedErrorSearchRequest: QualifiedErrorSearchRequest) => {
    try {
      setIsLoading(true);
      const qualifiedErrors = await qualifiedErrorApi.search(qualifiedErrorSearchRequest);
      setQualifiedErrors(qualifiedErrors);
      setHasSearched(true);
      setIsLoading(false);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setIsLoading(false);
    }
  }, [
    showErrorAlert
  ]);

  const handleReset = useCallback((resetForm: () => void) => {
    setQualifiedErrors([]);
    setHasSearched(false);
    resetForm();
  }, []);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'File Number', className: 'text-center', sortKey: 'fileNumber'},
      {title: 'Parcel ID', className: 'text-center', sortKey: 'parcel.parcelNumber'},
      {title: 'Parcel Address', className: 'text-center', sortKey: 'parcel.address.fullDisplay'},
      {title: 'Owner Names', className: 'text-center', sortKey: 'owner.ownerNames'},
      {title: 'Date Received', className: 'text-center', sortKey: 'receivedOn'}
    ],
    renderRow: (qualifiedError: QualifiedErrorSearchResponse) => {
      return <tr key={qualifiedError.id}>
        <td className="text-center text-nowrap">
          <Link to={`/qualified-errors/${qualifiedError.id}`}>
            {qualifiedError.fileNumber}
          </Link>
        </td>
        <td className="text-center text-nowrap">{qualifiedError.parcel.parcelNumber}</td>
        <td className="text-center">{qualifiedError.parcel.address.fullDisplay}</td>
        <td className="text-center">{qualifiedError.owner.ownerNames}</td>
        <td className="text-center text-nowrap">{formatDate(qualifiedError.receivedOn)}</td>
      </tr>;
    },
    initialValues: {direction: 'desc' as const, sortKey: 'fileNumber'},
    items: qualifiedErrors,
    noResultsMessage: messages.NO_QUALIFIED_ERRORS_FOUND,
    paginatorConfig: {
      perPage: 100
    }
  }), [
    qualifiedErrors
  ]);

  const initialValues: QualifiedErrorSearchRequest = useMemo(() => ({
    fileNumber: '',
    parcelNumber: '',
    parcelAddress: '',
    ownerNames: '',
    receivedOn: undefined
  }), []);

  return <>
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSearch}
            validationSchema={qualifiedErrorSearchSchema}>
      {(formikProps: FormikProps<FormikValues>) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Container fluid>
            {permissions.hasWriteAccess && <Row className="mb-4">
              <Col className="d-flex justify-content-end">
                <Button onClick={() => setModalIsOpen(true)}
                        color="primary">
                  Create Qualified Error
                </Button>
              </Col>
            </Row>}
            <Row>
              <Col>
                <Card className="mb-4">
                  <CardHeader>
                    Search Qualified Errors
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="2">
                        <FormikInput name="fileNumber"
                                     labelText="File Number"/>
                      </Col>
                      <Col sm="2">
                        <FormikInput name="parcelNumber"
                                     labelText="Parcel ID"/>
                      </Col>
                      <Col sm="3">
                        <FormikInput name="parcelAddress"
                                     labelText="Parcel Address"/>
                      </Col>
                      <Col sm="3">
                        <FormikInput name="ownerNames"
                                     labelText="Owner Names"/>
                      </Col>
                      <Col sm="2">
                        <FormikDatePicker name="receivedOn"
                                          labelText="Date Received"/>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <Button color="success"
                                size="sm"
                                type="submit"
                                disabled={formikProps.isSubmitting}
                                onClick={formikProps.submitForm}
                                className="mr-2">
                          Search
                        </Button>
                        <Button color="danger"
                                onClick={() => handleReset(formikProps.resetForm)}
                                size="sm">
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {isLoading && <ProgressIndicator className="mb-4"/>}
                {hasSearched && <Card>
                  <CardHeader>
                    Search Results
                  </CardHeader>
                  <CustomTable {...tableProps} />
                </Card>}
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
    <QualifiedErrorCreateModal isOpen={modalIsOpen}
                               toggleOpen={() => setModalIsOpen(!modalIsOpen)}/>
  </>;
};

export default QualifiedErrors;