import {formatNumber} from './util/formattingUtils';

// Application strings
export const APP_TITLE = 'ProServ: State of Michigan Department of Treasury';

// Growl notification messages
export const API_FAILURE = 'Unable to communicate with the ProServ service. Check your connection and try refreshing the page.';

export const APPEAL_ACCEPT_SUCCESSFUL = 'Appeal accepted.';
export const APPEAL_ACCEPT_FAILURE = 'Error accepting Appeal.';
export const APPEAL_CREATE_SUCCESSFUL = 'Appeal created.';
export const APPEAL_CREATE_FAILURE = 'Error creating Appeal.';
export const APPEAL_REJECT_SUCCESSFUL = 'Appeal rejected.';
export const APPEAL_REJECT_FAILURE = 'Error rejecting Appeal.';
export const APPEAL_SAVE_SUCCESSFUL = 'Appeal saved.';
export const APPEAL_SAVE_FAILURE = 'Error saving Appeal.';

export const BONA_FIDE_SAVE_FAILURE = 'Error saving Bona Fide';
export const BONA_FIDE_SAVE_SUCCESSFUL = 'Bona Fide saved.';
export const BONA_FIDE_TAX_DETAILS_DELETE_FAILURE = 'Error deleting Tax Details record.';
export const BONA_FIDE_TAX_DETAILS_DELETE_SUCCESSFUL = 'Tax Details Record deleted.';

export const CALL_SAVE_SUCCESSFUL = 'Call created.';
export const CALL_SAVE_FAILURE = 'Error creating call.';

export const DENIAL_SAVE_SUCCESSFUL = 'Denial saved.';
export const DENIAL_SAVE_FAILURE = 'Error saving Denial.';

export const DOCUMENT_DELETE_SUCCESSFUL = 'Document deleted.';
export const DOCUMENT_DELETE_FAILURE = 'Error deleting document.';
export const DOCUMENT_DOWNLOAD_FAILURE = 'Error downloading document.';
export const DOCUMENT_UPLOAD_SUCCESSFUL = 'Document uploaded.';
export const DOCUMENT_UPLOAD_FAILURE = 'Error uploading document.';

export const INTEREST_WAIVER_SAVE_SUCCESSFUL = 'Interest Waiver saved.';
export const INTEREST_WAIVER_SAVE_FAILURE = 'Error saving Interest Waiver.';

export const LETTER_DELETE_SUCCESSFUL = 'Letter deleted.';
export const LETTER_DELETE_FAILURE = 'Error deleting letter.';
export const LETTER_DOWNLOAD_FAILURE = 'Error downloading letter.';
export const LETTER_RECREATE_SUCCESSFUL = 'Letter recreated.';
export const LETTER_RECREATE_FAILURE = 'Error recreating letter.';
export const LETTER_BATCH_CREATE_SUCCESSFUL = 'Letter batch created.';
export const LETTER_BATCH_CREATE_FAILURE = 'Error creating letter batch.';
export const LETTER_BATCH_RECREATE_SUCCESSFUL = 'Letter batch recreated. Check back in a few minutes for new letter.';
export const LETTER_BATCH_RECREATE_FAILURE = 'Error recreating letter batch.';
export const LETTER_BATCH_DELETE_SUCCESSFUL = 'Letter batch deleted.';
export const LETTER_BATCH_DELETE_FAILURE = 'Error deleting letter batch.';
export const LETTER_BATCH_DOWNLOAD_FAILURE = 'Error downloading letter batch.';

export const NOTE_SAVE_SUCCESSFUL = 'Note created.';
export const NOTE_SAVE_FAILURE = 'Error creating note.';

export const QUALIFIED_ERROR_SAVE_SUCCESSFUL = 'Qualified Error saved.';
export const QUALIFIED_ERROR_SAVE_FAILURE = 'Error saving Qualified Error.';

// No Results Messages
export const NO_APPEALS_FOUND = 'No appeals found.';
export const NO_BONA_FIDES_FOUND = 'No bona fides found.';
export const NO_DENIALS_FOUND = 'No denials found.';
export const NO_INTEREST_WAIVERS_FOUND = 'No interest waivers found.';
export const NO_QUALIFIED_ERRORS_FOUND = 'No qualified errors found.';
export const NO_DENIAL_LETTERS_AVAILABLE = 'No denials available to generate letters.';

// Validation messages
export const INVALID_UPLOAD_FILETYPE = 'Invalid file type. Allowed File Types are .csv, .doc, .docx, .eml, .jpg, .jpeg, .msg, .pdf, .png, .txt, .xls, .xlsm, and .xlsx.';
export const MAX_CHARACTERS_10 = 'Must be less than 10 characters.';
export const MAX_FILE_SIZE = 'File size must not be larger than 10MB.';
export const MUST_BE_GREATER_THAN_0 = 'Must be greater than 0.';
export const MUST_BE_LESS_THAN_OR_EQUAL_TO_100 = 'Must be less than or equal to 100.';
export const MUST_BE_LESS_THAN_OR_EQUAL_TO_400 = 'Must be less than or equal to 400.';
export const MUST_BE_NUMBER = 'Must be a number.';
export const MUST_BE_UNIQUE_YEARS = 'Years must be unique.';
export const MUST_BE_UNIQUE_SEASON_PER_YEAR = 'Seasons must be unique per year.';
export const CONFLICTING_TAX_LEVY = 'A conflicting tax levy has previously been selected for this year.';
export const MUST_BE_VALID_DATE = 'Must be a valid date.';
export const REQUIRED = 'Required';

export const maxValue = (max: number) => `Must be less than or equal to ${formatNumber(max)}`;
