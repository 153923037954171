import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikPhoneInput, useUserContext} from '@reasoncorp/kyber-js';

import {interestWaiverOwnerSchema} from '../../schema/interestWaiver';
import {InterestWaiverResponse} from '../../types/interestWaiver';
import {InterestWaiverOwnerRequest, InterestWaiverRequest} from '../../types/request/interestWaiver';

type Props = {
  interestWaiver: InterestWaiverResponse
  onSave: (interestWaiverRequest: InterestWaiverRequest) => void
}

const InterestWaiverOwnerCard = ({
                                   interestWaiver,
                                   onSave
                                 }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (interestWaiverOwnerRequest: InterestWaiverOwnerRequest) => {
    const interestWaiverRequest = {
      ...interestWaiver,
      ...interestWaiverOwnerRequest
    } as InterestWaiverRequest;

    await onSave(interestWaiverRequest);
    setModalIsOpen(false);
  }, [
    onSave,
    interestWaiver
  ]);

  const initialValues: InterestWaiverOwnerRequest = useMemo(() => ({
    owner: interestWaiver.owner,
    ownerPhoneNumber: {
      owner: interestWaiver?.ownerPhoneNumber?.owner ?? '',
      coOwner: interestWaiver?.ownerPhoneNumber?.coOwner ?? ''
    }
  }), [
    interestWaiver
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Owner Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Owner</th>
            <th>Owner Phone #</th>
            <th>Co-Owner</th>
            <th>Co-Owner Phone #</th>
            <th>Additional Owner(s)</th>
            <th>Owner Company</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{interestWaiver.owner.ownerName}&nbsp;</td>
            <td>{interestWaiver.ownerPhoneNumber.owner}</td>
            <td>{interestWaiver.owner.coOwnerName}&nbsp;</td>
            <td>{interestWaiver.ownerPhoneNumber.coOwner}</td>
            <td>{interestWaiver.owner.ownerCareOf}&nbsp;</td>
            <td>{interestWaiver.owner.ownerCompany}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Owner Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={interestWaiverOwnerSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput name="owner.ownerFirstName"
                             autoFocus
                             labelText="Owner First Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.ownerMiddleName"
                             labelText="Owner Middle Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.ownerLastName"
                             labelText="Owner Last Name"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="owner.coOwnerFirstName"
                             labelText="Co-Owner First Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.coOwnerMiddleName"
                             labelText="Co-Owner Middle Name"/>
              </Col>
              <Col sm="4">
                <FormikInput name="owner.coOwnerLastName"
                             labelText="Co-Owner Last Name"/>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormikInput name="owner.ownerCareOf"
                             labelText="Additional Owner(s)"/>
              </Col>
              <Col sm="6">
                <FormikInput name="owner.ownerCompany"
                             labelText="Owner Company"/>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormikPhoneInput name="ownerPhoneNumber.owner"
                                  labelText="Owner Phone #"/>
              </Col>
              <Col sm="6">
                <FormikPhoneInput name="ownerPhoneNumber.coOwner"
                                  labelText="Co-Owner Phone #"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default InterestWaiverOwnerCard;