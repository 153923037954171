import {memo} from 'react';
import {Button, Card, CardHeader, Table} from 'reactstrap';

import {DenialResponse} from '../../types/denial';
import {formatDate} from '../../util';

type Props = {
  denial: DenialResponse
  processing: boolean
  onScanDownload: () => void
  scanExists?: boolean
}

const DenialReasonInformationCard = ({
                                       denial,
                                       processing,
                                       onScanDownload,
                                       scanExists
                                     }: Props) => {
  return <Card className="DenialReasonInformation">
    <CardHeader>
      Reason Information
    </CardHeader>
    <Table bordered responsive>
      <thead>
        <tr>
          <th style={{width: '32%'}}>Reason ID</th>
          <th>Questionnaire Sent</th>
          <th>Questionnaire Received</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {denial.reasonId}&nbsp;
          </td>
          <td>
            {formatDate(denial.questionnaireSentOn)}
          </td>
          <td>
            {formatDate(denial.questionnaireReceivedOn)}
          </td>
        </tr>
        <tr className="border-top-3">
          <th scope="row">Reason Comments</th>
          <td colSpan={2}>
            {denial.reasonComments}
          </td>
        </tr>
        <tr>
          <th scope="row">Reason Phone Comments</th>
          <td colSpan={2}>
            {denial.reasonPhoneComments}
          </td>
        </tr>
        <tr className="align-middle hide-print">
          <th scope="row" className="text-nowrap">Reason MI PRE Scanned Documents</th>
          <td colSpan={2}>
            {scanExists && <Button color="link"
                                   className="p-0 line-height-1"
                                   disabled={processing}
                                   onClick={onScanDownload}>
              {denial.reasonId}.pdf
            </Button>}
          </td>
        </tr>
      </tbody>
    </Table>
  </Card>;
};

export default memo(DenialReasonInformationCard);