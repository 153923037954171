import {ChangeEvent, useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik, FormikProps} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, FormikFileInput, FormikNumberInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {YearDeniedReview} from '../../types';
import {AppealResponse} from '../../types/appeal';
import {YearDeniedReviewRequest} from '../../types/request';
import {appealSupervisorReviewSchema} from '../../schema/appeal';
import {formatPercent, getAvailableDenialYears, isNumber} from '../../util';
import {CollectionContext} from '../../contexts';

type Props = {
  appeal: AppealResponse
  onDownloadFile: (path: string) => void
  onSave: (formData: FormData) => void
  openAcceptModal: () => void
  openRejectModal: () => void
}

type Values = {
  supervisorDecision?: string
  finalDirDetermination?: File | null
  yearDeniedReviews: YearDeniedReview[]
  uniqueYearsErrorMessage?: string // can be added via validation schema
}

const AppealSupervisorReviewCard = ({
                                      appeal,
                                      onDownloadFile,
                                      openRejectModal,
                                      openAcceptModal,
                                      onSave
                                    }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasRemovedYear, setHasRemovedYear] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const shouldDeniedPercentDefaultTo0 = useMemo(() => {
    return appeal.analystRecommendation === 'MIKELONIS' || appeal.analystRecommendation === 'OVERTURN';
  }, [
    appeal
  ]);

  const initialValues: Values = useMemo(() => ({
    supervisorDecision: appeal.supervisorDecision,
    yearDeniedReviews: appeal.yearDeniedReviews,
    finalDirDetermination: null
  }), [
    appeal
  ]);

  const handleSave = useCallback(async (appealSupervisorReviewRequest: Values) => {
    const formData = new FormData();

    if (appealSupervisorReviewRequest.supervisorDecision) {
      formData.append('supervisorDecision', appealSupervisorReviewRequest.supervisorDecision);
    }

    if (appealSupervisorReviewRequest.finalDirDetermination) {
      formData.append('finalDirDetermination', appealSupervisorReviewRequest.finalDirDetermination as File);
    }

    for (let i = 0; i < appealSupervisorReviewRequest.yearDeniedReviews.length; i++) {
      formData.set(
        `yearDeniedReviews[${i}].year`,
        appealSupervisorReviewRequest.yearDeniedReviews[i].year.toString()
      );
      formData.set(
        `yearDeniedReviews[${i}].deniedPercent`,
        appealSupervisorReviewRequest.yearDeniedReviews[i].deniedPercent.toString()
      );
      formData.set(
        `yearDeniedReviews[${i}].season`,
        appealSupervisorReviewRequest.yearDeniedReviews[i].season || ''
      );
      formData.set(
        `yearDeniedReviews[${i}].decision`,
        appealSupervisorReviewRequest.yearDeniedReviews[i].decision || ''
      );
      formData.set(
        `yearDeniedReviews[${i}].id`,
        appealSupervisorReviewRequest.yearDeniedReviews[i]?.id?.toString() ?? ''
      );
    }

    await onSave(formData);
    setModalIsOpen(false);
  }, [
    onSave
  ]);

  const handleAddYear = useCallback((formikProps: FormikProps<Values>) => {
    const newYear = {
      year: '',
      season: '',
      decision: '',
      deniedPercent: shouldDeniedPercentDefaultTo0 ? 0 : ''
    } as YearDeniedReviewRequest;

    formikProps.setFieldValue('yearDeniedReviews',
      [formikProps.values.yearDeniedReviews, ...[newYear]].flat()
    );
  }, [
    shouldDeniedPercentDefaultTo0
  ]);

  const handleRemoveYear = useCallback((formikProps: FormikProps<Values>, index: number) => {
    const yearDeniedReviews = [...formikProps.values.yearDeniedReviews];
    yearDeniedReviews.splice(index, 1);
    formikProps.setFieldValue('yearDeniedReviews', yearDeniedReviews);
    setHasRemovedYear(true);
  }, []);

  const handleDecisionChange = useCallback((formikProps: FormikProps<Values>,
                                            index: number) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === 'VALID') {
        formikProps.setFieldValue(`yearDeniedReviews[${index}].deniedPercent`, 0);
      } else if (e.target.value === 'DENIED') {
        formikProps.setFieldValue(`yearDeniedReviews[${index}].deniedPercent`, '');
      }
    }, []);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Supervisor Appeal Review and Final Recommendation
          </Col>

          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <>
              <Button color="primary"
                      className="mr-2"
                      size="sm"
                      disabled={appeal.status !== 'PENDING_SUPERVISOR_REVIEW'}
                      onClick={openAcceptModal}>
                Accept
              </Button>
              <Button color="primary"
                      className="mr-2"
                      size="sm"
                      disabled={appeal.status !== 'PENDING_SUPERVISOR_REVIEW'}
                      onClick={openRejectModal}>
                Reject
              </Button>
              <Button color="primary"
                      onClick={() => setModalIsOpen(true)}
                      size="sm">
                Edit
              </Button>
            </>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-5">Year</th>
            <th className="w-25">Summer or Winter</th>
            <th className="w-30">Valid or Denied</th>
            <th>Percent Denied</th>
            <th>Percent Allowed</th>
          </tr>
        </thead>
        <tbody>
          {appeal.yearDeniedReviews.length === 0 && <tr>
            <td colSpan={4}>No year denied reviews.</td>
          </tr>}
          {appeal.yearDeniedReviews.map(yearDeniedReview => <tr key={yearDeniedReview.year}>
            <th scope="row">{yearDeniedReview.year}</th>
            <td>{yearDeniedReview.season}</td>
            <td>{yearDeniedReview.decision}</td>
            <td>{formatPercent(yearDeniedReview.deniedPercent)}</td>
            <td>{formatPercent(yearDeniedReview.allowedPercent)}</td>
          </tr>)}
        </tbody>
      </Table>
      <Table responsive bordered>
        <thead>
          <tr className="border-top-3">
            <th className="w-30">Supervisor Final Recommendation</th>
            <th className="w-30 hide-print">Supervisor Final DIR Determination</th>
            <th className="hide-print">Final DIR Determination</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{appeal.supervisorDecision}&nbsp;</td>
            <td className="hide-print">
              {appeal.finalDirDeterminationLetter &&
                <Button color="link"
                        className="p-0 line-height-1"
                        onClick={() => onDownloadFile(appeal!.finalDirDeterminationLetter!.downloadLink)}>
                  {appeal.finalDirDeterminationLetter.displayFilename}
                </Button>}
            </td>
            <td className="hide-print">
              {appeal.finalDirDeterminationDocument &&
                <Button color="link"
                        className="p-0 line-height-1"
                        onClick={() => onDownloadFile(appeal!.finalDirDeterminationDocument!.downloadLink)}>
                  {appeal.finalDirDeterminationDocument.filename}
                </Button>}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="xl">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5 mb-0">
        Edit Supervisor Appeal Review and Final Recommendation
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={appealSupervisorReviewSchema}>
        {(formikProps) => (<>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th className="w-10">Year</th>
                <th className="w-20">Summer or Winter</th>
                <th className="w-30">Valid or Denied</th>
                <th className="text-right w-20">Percent Denied</th>
                <th className="text-right w-20">Percent Allowed</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {formikProps.values.yearDeniedReviews.length === 0 && <tr>
                <td colSpan={5}>No year denied reviews.</td>
              </tr>}
              {formikProps.values.yearDeniedReviews.map((_, index) => <>
                  <tr key={`year-denied-reviews-${index}`}>
                    <th scope="row" className="align-middle text-center">
                      <FormikSelect autoFocus={index === 0}
                                    name={`yearDeniedReviews[${index}].year`}
                                    formGroupClass="mb-0"
                                    disableFloatingLabel
                                    ariaLabel="Year">
                        <option value="">Select</option>
                        {getAvailableDenialYears.map(year => <option value={year} key={year}>{year}</option>)}
                      </FormikSelect>
                    </th>
                    <td className="align-middle text-center">
                      <FormikSelect name={`yearDeniedReviews[${index}].season`}
                                    formGroupClass="mb-0"
                                    disableFloatingLabel={true}
                                    ariaLabel="Summer or Winter">
                        <option value="">Select</option>
                        <option value="SUMMER" key="SUMMER">SUMMER</option>
                        <option value="WINTER" key="WINTER">WINTER</option>
                      </FormikSelect>
                    </td>
                    <td>
                      <FormikSelect name={`yearDeniedReviews[${index}].decision`}
                                    formGroupClass="mb-0"
                                    disableFloatingLabel={true}
                                    onChange={handleDecisionChange(formikProps, index)}
                                    ariaLabel="Decision">
                        <option value="">Select</option>
                        <option value="VALID" key="VALID">Valid</option>
                        <option value="DENIED" key="DENY">Denied</option>
                      </FormikSelect>
                    </td>
                    <td>
                      <FormikNumberInput name={`yearDeniedReviews[${index}].deniedPercent`}
                                         formGroupClass="mb-0"
                                         className="text-right"
                                         disabled={formikProps.values.yearDeniedReviews[index].decision === 'VALID'}
                                         maxLength={3}
                                         disableFloatingLabel={true}
                                         ariaLabel="Percent Denied"
                      />
                    </td>
                    <td className="text-right pt-3">
                      {isNumber(formikProps.values.yearDeniedReviews[index].deniedPercent) &&
                        formatPercent(100 - formikProps.values.yearDeniedReviews[index].deniedPercent)}
                    </td>
                    <td className="align-middle text-center">
                      <ButtonIcon icon="trash"
                                  onClick={() => handleRemoveYear(formikProps, index)}
                                  className="text-danger"
                                  title={`Delete Year ${formikProps.values.yearDeniedReviews[index].year}`}
                                  ariaLabel={`Delete Year ${formikProps.values.yearDeniedReviews[index].year}`}/>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          <ModalBody className="pt-0">
            <Row className="row-border-top-3 mt-0">
              <Col>
                <FormikSelect name="supervisorDecision"
                              labelText="Supervisor Final Recommendation">
                  <option value="">Select</option>
                  {collections?.supervisorFinalDecisions.map(supervisorFinalDecision =>
                    <option key={supervisorFinalDecision} value={supervisorFinalDecision}>{supervisorFinalDecision}</option>)}
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikFileInput name="finalDirDetermination"
                                 formGroupClass="mb-0"
                                 labelText="Supervisor Final DIR Determination"/>
              </Col>
            </Row>
          </ModalBody>
          {formikProps.errors.uniqueYearsErrorMessage &&
            <Row className="ml-1 mr-1 pt-2 pb-2">
              <Col className="text-danger font-weight-bold">
                {formikProps.errors.uniqueYearsErrorMessage}
              </Col>
            </Row>
          }
          <ModalFooter className="pr-0 pl-0">
            <Row className="w-100 d-flex m-0">
              <Col className="align-self-center" sm="4">
                <Button size="sm"
                        outline
                        disabled={formikProps.values.yearDeniedReviews.length >= 8}
                        onClick={() => handleAddYear(formikProps)}
                        color="primary">
                  <FontAwesomeIcon icon="plus-circle" className="mr-2"/>
                  Add Year
                </Button>
              </Col>
              <Col className="d-flex justify-content-end" sm="8">
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || (!hasRemovedYear && !formikProps.dirty) || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default AppealSupervisorReviewCard;