import {memo, useCallback, useMemo} from 'react';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import {useNavigate} from 'react-router-dom';

import {ButtonIcon, CustomTable, downloadUtils, useAlerts} from '@reasoncorp/kyber-js';

import {DocumentResponse} from '../../types';
import {documentApi} from '../../api';
import * as messages from '../../messages';
import {formatDateTime} from '../../util';

type Props = {
  letters: DocumentResponse[]
}

const LettersCard = ({
                       letters
                     }: Props) => {
  const {showErrorAlert} = useAlerts();
  const navigate = useNavigate();

  const handleDownload = useCallback(async (path: string) => {
    try {
      await downloadUtils.downloadFile(documentApi.getSignedUrl(path));
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
    }
  }, [
    showErrorAlert
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Date', sortKey: 'createdAt', className: 'w-15 text-center'},
      {title: 'File Name', sortKey: 'displayFilename', className: 'w-25'},
      {title: 'User', sortKey: 'username', className: 'w-30'},
      {title: 'Download', sortDisabled: true, className: 'w-15 text-center'},
      {title: 'View Batch', sortDisabled: true, className: 'w-15 text-center'}
    ],
    items: letters,
    initialSort: {sortKey: 'createdAt', direction: 'desc' as 'desc'},
    noResultsMessage: 'No letters available.',
    renderRow: (document: DocumentResponse) => {
      return <tr key={document.id}>
        <td className="text-center align-middle">{formatDateTime(document.createdAt)}</td>
        <td className="align-middle">{document.displayFilename}</td>
        <td className="align-middle">{document.username}</td>
        <td className="text-center align-middle">
          <ButtonIcon icon="paperclip"
                      className="text-success"
                      onClick={async () => await handleDownload(document.downloadLink)}
                      ariaLabel={`download document ${document.displayFilename}`}
                      title={`download document ${document.displayFilename}`}/>
        </td>
        <td className="text-center align-middle">
          {document.viewLink && <ButtonIcon icon="external-link-alt"
                                            className="text-success"
                                            onClick={() => document.viewLink ? navigate(document.viewLink) : null}
                                            ariaLabel={`View document ${document.displayFilename}`}
                                            title={`View document ${document.displayFilename}`}/>}
        </td>
      </tr>;
    }
  }), [
    navigate,
    letters,
    handleDownload
  ]);

  return <>
    <Card className="hide-print">
      <CardHeader>
        <Row>
          <Col sm="12" className="align-self-center">
            Letters
          </Col>
        </Row>
      </CardHeader>
      <CustomTable {...tableProps} />
    </Card>
  </>;
};

export default memo(LettersCard);