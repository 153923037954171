import {ReactNode, useCallback, useEffect, useState} from 'react';

import {useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import CollectionContext from './CollectionContext';
import {CollectionResponse} from '../types';
import {collectionApi} from '../api';
import * as messages from '../messages';

type  Props = {
  children: ReactNode
}

const CollectionProvider = ({
                              children
                            }: Props) => {
  const [collections, setCollections] = useState<CollectionResponse | undefined>(undefined);
  const {loadingUser} = useUserContext();
  const {showErrorAlert} = useAlerts();

  useEffect(() => {
    const loadCollections = async () => {
      try {
        if (!loadingUser) {
          const collections = await collectionApi.find();
          setCollections(collections);
        }
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
      }
    };

    void loadCollections();
  }, [
    loadingUser,
    showErrorAlert
  ]);

  const filterLocalUnitsByCountyId = useCallback((countyId?: number | string | null) => {
    return collections?.localUnits?.filter(lu => lu.countyId === Number(countyId)) ?? [];
  }, [
    collections?.localUnits
  ]);

  return <CollectionContext.Provider value={{
    collections,
    filterLocalUnitsByCountyId
  }}>
    {children}
  </CollectionContext.Provider>;
};

export default CollectionProvider;