import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Row, Table} from 'reactstrap';
import {sum} from 'lodash';

import {Duration, DurationType, useUserContext} from '@reasoncorp/kyber-js';

import {formatMoney} from '../../util';
import {BonaFideDenialYear, BonaFideResponse} from '../../types/bonaFide';
import {BonaFideRequest} from '../../types/request/bonaFide';
import BonaFideDenialYearModal from './BonaFideDenialYearModal';
import BonaFideDeleteYearModal from './BonaFideDeleteYearModal';
import BonaFideDenialYearCard from './BonaFideDenialYearCard';

type Props = {
  bonaFide: BonaFideResponse
  onSave: (bonaFideRequest: BonaFideRequest, isDeletingYear?: boolean) => void
}

const BonaFideTaxDueDetailsCard = ({
                                     bonaFide,
                                     onSave
                                   }: Props) => {
  const [isDenialYearModalOpen, setIsDenialYearModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {permissions} = useUserContext();
  const [selectedDenialYear, setSelectedDenialYear] = useState<BonaFideDenialYear | undefined>(undefined);

  const handleSave = useCallback((shouldAddAnother: boolean) => async (bonaFideDenialYear: BonaFideDenialYear) => {
    setIsDenialYearModalOpen(false);
    const denialYears = (bonaFideDenialYear.id !== null && bonaFideDenialYear.id !== undefined) ? [
      ...bonaFide.denialYears.filter(dy => dy.id !== bonaFideDenialYear.id),
      bonaFideDenialYear
    ] : [...bonaFide.denialYears, bonaFideDenialYear];

    const bonaFideRequest = {
      ...bonaFide,
      denialYears
    } as BonaFideRequest;

    await onSave(bonaFideRequest);

    if (shouldAddAnother) {
      setTimeout(() => {
        setSelectedDenialYear(undefined);
        setIsDenialYearModalOpen(true);
      }, Duration.of(1, DurationType.SECONDS));
    }
  }, [
    bonaFide,
    onSave
  ]);

  const handleDelete = useCallback(async (bonaFideDenialYear: BonaFideDenialYear) => {
    if (isDeleteModalOpen) {
      const denialYears = bonaFide.denialYears.filter(dy => dy.id !== bonaFideDenialYear.id);
      const bonaFideRequest = {
        ...bonaFide,
        denialYears
      } as BonaFideRequest;

      await onSave(bonaFideRequest, true);
      setSelectedDenialYear(undefined);
      setIsDeleteModalOpen(false);
    } else {
      setSelectedDenialYear(bonaFideDenialYear);
      setIsDeleteModalOpen(true);
    }
  }, [
    bonaFide,
    isDeleteModalOpen,
    onSave
  ]);

  const handleDeleteCancel = useCallback(() => {
    setSelectedDenialYear(undefined);
    setIsDeleteModalOpen(false);
  }, []);

  const handleEdit = useCallback((denialYear: BonaFideDenialYear) => {
    setSelectedDenialYear(denialYear);
    setIsDenialYearModalOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setSelectedDenialYear(undefined);
    setIsDenialYearModalOpen(false);
  }, []);

  const totalDue = useMemo(() => {
    return sum(
      bonaFide
        .denialYears
        .filter(dy => dy.shouldIssueBill)
        .map(dy => dy.totalDue)
    );
  }, [
    bonaFide
  ]);

  return <>
    <Card className="BonaFideDenialYears">
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Tax Due Billing Details
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setIsDenialYearModalOpen(true)}
                                                   size="sm">
              Add Year
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      {bonaFide.denialYears.map((denialYear: BonaFideDenialYear) =>
        <BonaFideDenialYearCard denialYear={denialYear}
                                onEdit={handleEdit}
                                onDelete={handleDelete}/>)}
      {bonaFide.denialYears.length === 0 && <Row className="p-4">
        <Col className="font-weight-bold">
          No denial years.
        </Col>
      </Row>}
      <Table responsive bordered>
        <tbody>
          <tr className="BonaFideDenialYearsTotal">
            <td colSpan={6}/>
            <td colSpan={6} className="font-weight-bold text-right text-danger">Total Due: {formatMoney(totalDue)}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <BonaFideDenialYearModal isOpen={isDenialYearModalOpen}
                             onSubmit={handleSave(false)}
                             onSubmitAndAddAnother={handleSave(true)}
                             onCancel={() => handleCancel()}
                             denialYears={bonaFide.denialYears}
                             selectedDenialYear={selectedDenialYear}/>
    <BonaFideDeleteYearModal isOpen={isDeleteModalOpen}
                             onConfirm={handleDelete}
                             onCancel={handleDeleteCancel}
                             selectedDenialYear={selectedDenialYear}/>
  </>;
};

export default BonaFideTaxDueDetailsCard;