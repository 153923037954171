import {useCallback, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {useNavigate} from 'react-router-dom';

import {FormikDatePicker, FormikInput, FormikNumberInput, useAlerts} from '@reasoncorp/kyber-js';

import {Parcel} from '../../types';
import {InterestWaiverRequest} from '../../types/request/interestWaiver';
import {interestWaiverCreateSchema} from '../../schema/interestWaiver';
import {interestWaiverApi} from '../../api';
import * as messages from '../../messages';
import {CountySelect, LocalUnitSelect} from '../shared';

type Props = {
  isOpen: boolean
  toggleOpen: () => void
  parcel?: Parcel
  onSave?: () => void
  denialId?: number
  bonaFideId?: number
}

const InterestWaiverCreateModal = ({
                                     isOpen,
                                     toggleOpen,
                                     parcel,
                                     onSave,
                                     denialId,
                                     bonaFideId
                                   }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const navigate = useNavigate();

  const initialValues: InterestWaiverRequest = useMemo(() => ({
    parcel: {
      parcelNumber: parcel?.parcelNumber ?? '',
      countyId: parcel?.countyId ?? null,
      localUnitId: parcel?.localUnitId ?? null,
      address: {
        directionPrefix: parcel?.address.directionPrefix ?? '',
        directionSuffix: parcel?.address.directionSuffix ?? '',
        streetNumber: parcel?.address.streetNumber ?? '',
        streetName: parcel?.address.streetName ?? '',
        streetType: parcel?.address.streetType ?? '',
        unit: parcel?.address.unit ?? '',
        city: parcel?.address.city ?? '',
        state: 'MI',
        zip: parcel?.address.zip ?? ''
      }
    },
    numberOfYearsRequested: '',
    receivedOn: null,
    denialId,
    bonaFideId
  }), [
    denialId,
    bonaFideId,
    parcel
  ]);

  const handleSave = useCallback(async (interestWaiverRequest: InterestWaiverRequest,
                                        formikHelpers: FormikHelpers<InterestWaiverRequest>) => {
    try {
      const interestWaiver = await interestWaiverApi.create(interestWaiverRequest);
      toggleOpen();
      showSuccessAlert(messages.INTEREST_WAIVER_SAVE_SUCCESSFUL);
      formikHelpers.resetForm();
      if (onSave) {
        onSave();
      } else {
        navigate(`/interest-waivers/${interestWaiver.id}`);
      }
    } catch (e) {
      showErrorAlert(messages.INTEREST_WAIVER_SAVE_FAILURE);
    }
  }, [
    onSave,
    navigate,
    showErrorAlert,
    showSuccessAlert,
    toggleOpen
  ]);

  return <Modal isOpen={isOpen}
                toggle={toggleOpen}
                autoFocus={false}
                returnFocusAfterClose={true}
                size="xl">
    <ModalHeader toggle={toggleOpen}
                 tag="h2"
                 className="h5 mb-0">
      Create New Interest Waiver
    </ModalHeader>
    <h3 className="h6 bg-light p-3">Property Information</h3>
    <Formik initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={handleSave}
            validationSchema={interestWaiverCreateSchema}>
      {(formikProps) => (<Form onSubmit={formikProps.submitForm} autoComplete="off">
        <ModalBody>
          <Row>
            <Col sm="4">
              <FormikInput name="parcel.parcelNumber"
                           autoFocus
                           labelText="Parcel #"/>
            </Col>
            <Col sm="4">
              <CountySelect name="parcel.countyId"
                            onChange={() => formikProps.setFieldValue('parcel.localUnitId', null)}/>
            </Col>
            <Col sm="4">
              <LocalUnitSelect name="parcel.localUnitId"
                               countyId={formikProps.values.parcel.countyId}/>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <FormikInput name="parcel.address.directionPrefix"
                           labelText="Dir. Prefix"/>
            </Col>
            <Col sm="2">
              <FormikInput name="parcel.address.streetNumber"
                           labelText="Street #"/>
            </Col>
            <Col sm="3">
              <FormikInput name="parcel.address.streetName"
                           labelText="Street Name"/>
            </Col>
            <Col sm="2">
              <FormikInput name="parcel.address.streetType"
                           labelText="Street Type"/>
            </Col>
            <Col sm="2">
              <FormikInput name="parcel.address.directionSuffix"
                           labelText="Dir. Suffix"/>
            </Col>
            <Col sm="1">
              <FormikInput name="parcel.address.unit"
                           labelText="APT #"/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <FormikInput name="parcel.address.city"
                           labelText="City"/>
            </Col>
            <Col sm="4">
              <FormikInput name="parcel.address.state"
                           disabled={true}
                           labelText="State Abbr"/>
            </Col>
            <Col sm="4">
              <FormikInput name="parcel.address.zip"
                           labelText="Zip Code"/>
            </Col>
          </Row>
        </ModalBody>
        <h3 className="h6 bg-light p-3">File Information</h3>
        <ModalBody>
          <Row>
            <Col sm="6">
              <FormikDatePicker name="receivedOn"
                                labelText="Date Received"/>
            </Col>
            <Col sm="6">
              <FormikNumberInput name="numberOfYearsRequested"
                                 labelText="Number of Requested Years"/>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="justify-content-end">
            <Col>
              <Button color="primary"
                      className="mr-2"
                      disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}
                      onClick={formikProps.submitForm}>
                Save
              </Button>
              <Button color="secondary"
                      onClick={toggleOpen}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Form>)}
    </Formik>
  </Modal>;
};

export default InterestWaiverCreateModal;