import {Navigate, Route, Routes} from 'react-router-dom';

import {Alerts, Footer, NotFound, SkipNav, useUserContext} from '@reasoncorp/kyber-js';

import {ProServAppBar} from './components';
import {Appeal, Appeals, InterestWaiver, InterestWaivers, QualifiedError, QualifiedErrors, Reports} from './containers';
import {
  Denial,
  DenialImports,
  DenialImportsHistory,
  DenialLetterBatch,
  DenialLetterBatches,
  DenialLetters,
  Denials
} from './containers/denials';
import {BonaFide, BonaFideLetterBatch, BonaFideLetterBatches, BonaFideLetters, BonaFides} from './containers/bonaFides';
import './font-awesome-icons';

const App = () => {
  const {loadingUser, currentUser, permissions} = useUserContext();

  return <>
    <SkipNav/>
    <ProServAppBar currentUser={currentUser}
                   hasImportAndLetterAccess={permissions.hasImportAndLetterAccess}/>
    {!loadingUser && currentUser &&
      <main role="main" id="content">
        <Routes>
          <Route path="/" element={<Navigate replace to="/denials"/>}/>
          <Route path="/appeals" element={<Appeals/>}/>
          <Route path="/appeals/:id" element={<Appeal/>}/>
          <Route path="/interest-waivers" element={<InterestWaivers/>}/>
          <Route path="/interest-waivers/:id" element={<InterestWaiver/>}/>
          <Route path="/qualified-errors" element={<QualifiedErrors/>}/>
          <Route path="/qualified-errors/:id" element={<QualifiedError/>}/>
          <Route path="/bona-fides" element={<BonaFides/>}/>
          <Route path="/bona-fides/:id" element={<BonaFide/>}/>
          {permissions.hasWriteAccess && <>
            <Route path="/bona-fides/letters" element={<BonaFideLetters/>}/>
            <Route path="/bona-fides/letters/batches" element={<BonaFideLetterBatches/>}/>
            <Route path="/bona-fides/letters/batches/:id" element={<BonaFideLetterBatch/>}/>
          </>}
          <Route path="/denials" element={<Denials/>}/>
          <Route path="/denials/:id" element={<Denial/>}/>
          {permissions.hasImportAndLetterAccess && <>
            <Route path="/denials/imports" element={<DenialImports/>}/>
            <Route path="/denials/imports/history/:batchId" element={<DenialImportsHistory/>}/>
            <Route path="/denials/imports/history" element={<DenialImportsHistory/>}/>
            <Route path="/denials/letters" element={<DenialLetters/>}/>
            <Route path="/denials/letters/batches" element={<DenialLetterBatches/>}/>
            <Route path="/denials/letters/batches/:id" element={<DenialLetterBatch/>}/>
            <Route path="/reports" element={<Reports/>}/>
          </>}
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </main>
    }
    <Footer brand="State of Michigan"
            brandLink="https://michigan.gov"
            footerLinks={[
              {name: 'MI.gov', to: 'https://michigan.gov'},
              {name: 'Home', to: '/'},
              {name: 'Contact', to: 'https://www.michigan.gov/treasury/0,4679,7-121--8483--,00.html'},
              {name: 'Policies', to: 'https://www.michigan.gov/policies'}
            ]}
    />
    <Alerts/>
  </>;
};

export default App;
