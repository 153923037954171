import {ConfirmationModal} from '@reasoncorp/kyber-js';

import {BonaFideDenialYear} from '../../types/bonaFide';

type Props = {
  selectedDenialYear?: BonaFideDenialYear;
  onConfirm: (selectedDenialYear: BonaFideDenialYear) => void
  onCancel: () => void;
  isOpen: boolean
}

const BonaFideDeleteYearModal = ({
                                   selectedDenialYear,
                                   onCancel,
                                   onConfirm,
                                   isOpen
                                 }: Props) => {
  if (selectedDenialYear) {
    return <ConfirmationModal isOpen={isOpen}
                              confirmCallback={() => onConfirm(selectedDenialYear)}
                              cancelCallback={() => onCancel()}
                              size="lg"
                              confirmButtonText="Yes"
                              cancelButtonText="No"
                              title="Delete Tax Due Billing Details Record">
      <p>
        Are you sure you wish to delete the <span className="text-danger">{selectedDenialYear?.year} {selectedDenialYear?.taxLevySeason}</span> tax due billing details record?
      </p>
    </ConfirmationModal>;
  } else {
    return null;
  }
};

export default BonaFideDeleteYearModal;