import {County, FormikSelect} from '@reasoncorp/kyber-js';

import {useCollections} from '../../hooks';

type Props = {
  onChange: () => void
  name: string
}

const CountySelect = ({
                        onChange,
                        name
                      }: Props) => {
  const {collections} = useCollections();

  return <FormikSelect name={name}
                       onChange={onChange}
                       labelText="County">
    <option value="">Select</option>
    {collections?.counties.map((county: County) =>
      <option value={county.id} key={county.id}>
        {county.displayName}
      </option>)
    }
  </FormikSelect>;
};

export default CountySelect;