import {format, isValid, parseISO} from 'date-fns';

export const formatDate = (dateValue: Date | string | null | undefined, dateFormat = 'MM/dd/yyyy') => {
  if (dateValue instanceof Date && isValid(dateValue)) {
    return format(dateValue, dateFormat);
  } else {
    const parsedDate = parseISO(dateValue as string);
    return (dateValue != null && isValid(parsedDate)) ? format(parsedDate, dateFormat) : undefined;
  }
};

export const formatDateTime = (dateValue: Date | string | null) => formatDate(dateValue, 'MM/dd/yyyy h:mm a');

export const formatBoolean = (val?: boolean | null) => {
  if (val === undefined || val === null) {
    return '';
  }
  return val ? 'Yes' : 'No';
};

export const formatNumber = (val?: number | null | string) => {
  if (val === undefined || val === null || typeof val === 'string') {
    return '';
  }

  return val.toLocaleString();
};

export const formatMoney = (val?: number | null | string) => {
  if (val === undefined || val === null || typeof val === 'string') {
    return '';
  }

  const absoluteValue = Math.abs(val);

  if (val < 0) {
    return '-$' + absoluteValue.toLocaleString();
  } else {
    return '$' + absoluteValue.toLocaleString();
  }
};

export const formatDecimal = (val?: number | null | string, decimalPlaces: number = 4) => {
  if (val === undefined || val === null || typeof val === 'string') {
    return '';
  }

  return val.toFixed(decimalPlaces);
};


export const formatPercent = (val?: number | null) => {
  if (val === undefined || val === null) {
    return '';
  }

  return val + '%';
};
