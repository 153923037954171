import {memo} from 'react';
import {Button, Nav, NavItem, NavLink} from 'reactstrap';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';

const navItems = [
  {text: 'Search Bona Fides', path: '/bona-fides'},
  {text: 'Generate Voucher Letters', path: '/bona-fides/letters'},
  {text: 'Voucher Letter Batches', path: '/bona-fides/letters/batches'}
];

type Props = {
  displayCreateButton?: boolean
  onCreateClick?: () => void
}

const BonaFideNavBar = ({
                          onCreateClick,
                          displayCreateButton
                        }: Props) => {
  const location = useLocation();

  return <>
    <Nav>
      {navItems.map(navItem => <NavItem active={location.pathname === navItem.path} key={navItem.text}>
        <NavLink to={navItem.path} tag={RouterLink}>
          {navItem.text}
        </NavLink>
      </NavItem>)}
    </Nav>
    {displayCreateButton && onCreateClick &&
      <Button color="primary"
              className="ml-3"
              onClick={onCreateClick}>
        Create Bona Fide
      </Button>
    }
  </>;
};

export default memo(BonaFideNavBar);