import {DocumentResponse} from '../types';
import {InterestWaiverResponse, InterestWaiverSearchResponse} from '../types/interestWaiver';
import {InterestWaiverRequest, InterestWaiverSearchRequest} from '../types/request/interestWaiver';
import {proServApi} from './apiUtils';

export const search = (interestWaiverSearchRequest: InterestWaiverSearchRequest): Promise<InterestWaiverSearchResponse[]> => proServApi.postWithJsonResponse(
  `/interest-waivers/search`,
  {
    body: JSON.stringify(interestWaiverSearchRequest)
  }
);

export const find = (id: number): Promise<InterestWaiverResponse> => proServApi.getWithJsonResponse(
  `/interest-waivers/${id}`
);

export const findDocuments = (id: number): Promise<DocumentResponse[]> => proServApi.getWithJsonResponse(
  `/interest-waivers/${id}/documents`
);

export const create = (interestWaiverRequest: InterestWaiverRequest): Promise<InterestWaiverResponse> => proServApi.postWithJsonResponse(
  `/interest-waivers`,
  {
    body: JSON.stringify(interestWaiverRequest)
  }
);

export const save = (id: number, interestWaiverRequest: InterestWaiverRequest): Promise<InterestWaiverResponse> => proServApi.putWithJsonResponse(
  `/interest-waivers/${id}`,
  {
    body: JSON.stringify(interestWaiverRequest)
  }
);

export const saveAnalystRecommendation = (id: number,
                                          formData: FormData): Promise<InterestWaiverResponse> => proServApi.patchWithJsonResponse(
  `/interest-waivers/${id}/analyst-recommendation`,
  {
    body: formData
  }
);

export const saveSupervisorDetermination = (id: number,
                                            formData: FormData): Promise<InterestWaiverResponse> => proServApi.patchWithJsonResponse(
  `/interest-waivers/${id}/supervisor-determination`,
  {
    body: formData
  }
);