import {memo, useMemo} from 'react';
import {Badge} from 'reactstrap';

type Props = {
  status: 'PROCESSING' | 'ACTIVE' | 'FAILED'
}

const LetterBatchStatusBadge = ({
                                  status
                                }: Props) => {
  const color = useMemo(() => {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'FAILED':
        return 'danger';
      case 'PROCESSING':
        return 'primary';
    }
  }, [
    status
  ]);

  return <Badge color={color}>
    {status}
  </Badge>;
};

export default memo(LetterBatchStatusBadge);