import {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';

import {ExportBatchLite, ExportBatchReport, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {
  DenialImportBatchesTable,
  DenialImportSummaryTable,
  DenialImportTable,
  DenialNavBar
} from '../../components/denials';
import {importApi} from '../../api';

const DenialImportsHistory = () => {
  const navigate = useNavigate();
  const {batchId} = useParams<{batchId: string}>();
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({
    initialLoading: true,
    loadError: false,
    loadingBatch: true
  });
  const [selectedBatch, setSelectedBatch] = useState<ExportBatchLite | undefined>(undefined);
  const [selectedBatchId, setSelectedBatchId] = useState<number>(Number(batchId));
  const [exportBatches, setExportBatches] = useState<ExportBatchLite[]>([]);
  const [exportBatchReport, setExportBatchReport] = useState<ExportBatchReport | undefined>(undefined);

  useEffect(() => {
    const loadImportBatches = async () => {
      try {
        const exportBatches = await importApi.findBatches();
        setExportBatches(exportBatches);
        const selectedBatchId = Number(batchId ? batchId : exportBatches[0].batchId);
        setSelectedBatchId(selectedBatchId);
        setSelectedBatch(exportBatches.filter(exportBatch => exportBatch.batchId === selectedBatchId)?.[0] ?? exportBatches[0]);
        setLoadingState({loadError: false, initialLoading: false, loadingBatch: false});
      } catch (error) {
        setLoadingState({loadError: true, initialLoading: false, loadingBatch: false});
        showErrorAlert(messages.API_FAILURE);
      }
    };

    void loadImportBatches();
  }, [
    batchId,
    showErrorAlert
  ]);

  useEffect(() => {
    const loadImportBatch = async () => {
      if (selectedBatchId) {
        try {
          setLoadingState({loadError: false, loadingBatch: true, initialLoading: false});
          const exportBatchReport = await importApi.findBatch(selectedBatchId);
          setExportBatchReport(exportBatchReport);
          setLoadingState({loadError: false, loadingBatch: false, initialLoading: false});
        } catch (error) {
          setLoadingState({loadError: true, loadingBatch: false, initialLoading: false});
          showErrorAlert(messages.API_FAILURE);
        }
      }
    };

    void loadImportBatch();
  }, [
    showErrorAlert,
    selectedBatchId
  ]);

  const handleItemSelect = useCallback((exportBatch: ExportBatchLite) => {
    setSelectedBatchId(exportBatch.batchId);
    setSelectedBatch(exportBatch);
    navigate(`/imports/history/${exportBatch.batchId}`);
  }, [
    navigate
  ]);

  return <Container fluid className="ImportsHistory">
    <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <DenialNavBar/>
      </Col>
    </Row>
    {loadingState.initialLoading && <ProgressIndicator className="mb-4"/>}
    {!loadingState.initialLoading && !loadingState.loadError && selectedBatch && <Row>
      <Col xs="3">
        {exportBatches && <DenialImportBatchesTable onItemSelect={handleItemSelect}
                                                    exportBatches={exportBatches}/>}
      </Col>
      <Col xs="9">
        {loadingState.loadingBatch && <ProgressIndicator className="mb-4"/>}
        {selectedBatch && exportBatchReport && !loadingState.loadingBatch && <>
          <Row className="mb-4">
            <Col>
              <DenialImportSummaryTable selectedBatch={selectedBatch}
                                        exportSummaries={exportBatchReport.summaries}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <DenialImportTable items={exportBatchReport.items}/>
            </Col>
          </Row>
        </>}
      </Col>
    </Row>}
  </Container>;
};

export default DenialImportsHistory;