import {useEffect, useMemo, useState} from 'react';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';

import {CustomTable, PendingExport, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {DenialNavBar} from '../../components/denials';
import {importApi} from '../../api';
import * as messages from '../../messages';

const DenialImports = () => {
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loadError: false, loading: false});
  const [pendingExports, setPendingExports] = useState<PendingExport[]>([]);

  useEffect(() => {
    const loadPendingExports = async () => {
      setLoadingState({loadError: false, loading: true});
      try {
        const pendingExports = await importApi.findPending();
        setPendingExports(pendingExports);
        setLoadingState({loading: false, loadError: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadPendingExports();
  }, [
    showErrorAlert
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {sortKey: 'reasonId', title: 'Reason ID', className: 'text-center'},
      {sortKey: 'parcelNumber', title: 'Parcel ID', className: 'text-center'},
      {sortKey: 'county', title: 'County ', className: 'text-center'},
      {sortKey: 'localUnit', title: 'City/Township', className: 'text-center'},
      {sortKey: 'address', title: 'Address', className: 'text-center'},
      {sortKey: 'owner', title: 'Owner', className: 'text-center'},
      {sortKey: 'majorReason', title: 'Major Reason', className: 'text-center'}
    ],
    initialSort: {sortKey: 'reasonId', direction: 'asc' as const},
    items: pendingExports,
    paginatorConfig: {
      perPage: 100
    },
    noResultsMessage: 'No Imports are pending.',
    renderRow: (pendingExport: PendingExport) => {
      return (
        <tr key={pendingExport.reasonId}>
          <td className="text-center">
            {pendingExport.reasonId}
          </td>
          <td className="text-center">
            {pendingExport.parcelNumber}
          </td>
          <td className="text-center text-uppercase">
            {pendingExport.county.displayName}
          </td>
          <td className="text-center text-uppercase">
            {pendingExport.localUnitName}
          </td>
          <td className="text-center">
            {pendingExport.address}
          </td>
          <td className="text-center">
            {pendingExport.owner}
          </td>
          <td className="text-center">
            {pendingExport.majorReason}
          </td>
        </tr>
      );
    }
  }), [
    pendingExports
  ]);

  return <Container fluid>
    <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <DenialNavBar/>
      </Col>
    </Row>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && <Row>
      <Col>
        <Card>
          <CardHeader>
            <Row>
              <Col sm="6">Import Queue</Col>
              <Col sm="6" className="d-flex justify-content-end">{pendingExports.length} Pending Imports</Col>
            </Row>
          </CardHeader>
          <Row>
            <Col>
              <CustomTable {...tableProps}/>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>}
  </Container>;
};

export default DenialImports;