import * as Yup from 'yup';

import * as messages from '../messages';
import addressSchema from './addressSchema';

export default Yup.object({
  parcelNumber: Yup.string()
    .required(messages.REQUIRED),
  countyId: Yup.number()
    .nullable()
    .required(messages.REQUIRED),
  localUnitId: Yup.number()
    .nullable()
    .required(messages.REQUIRED),
  address: addressSchema
});