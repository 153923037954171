import {Col, Row} from 'reactstrap';

type Props = {
  children: React.ReactNode
  header: string
  childColumnClassName?: string
}

const BonaFideDenialYearFormRow = ({
                                     children,
                                     header,
                                     childColumnClassName = 'col-4'
                                   }: Props) => {
  return <Row className="p-2 BonaFideDenialYearFormRow">
    <Col className="font-weight-bold text-primary align-self-center col-8">
      {header}
    </Col>
    <Col className={`align-middle align-self-center col-4 ${childColumnClassName}`}>
      {children}
    </Col>
  </Row>;
};

export default BonaFideDenialYearFormRow;