import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikSelect, useUserContext, UsState} from '@reasoncorp/kyber-js';

import {PowerOfAttorney} from '../../types';
import {PowerOfAttorneyRequest} from '../../types/request';
import {powerOfAttorneySchema} from '../../schema';

type Props = {
  powerOfAttorney: PowerOfAttorney
  onSave: (powerOfAttorney: PowerOfAttorneyRequest) => void
}

const PowerOfAttorneyCard = ({
                               powerOfAttorney,
                               onSave
                             }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const initialValues: PowerOfAttorneyRequest = useMemo(() => ({
    name: powerOfAttorney.name,
    firmName: powerOfAttorney.firmName,
    address: powerOfAttorney.address,
    city: powerOfAttorney.city,
    state: powerOfAttorney.state,
    zip: powerOfAttorney.zip
  }), [
    powerOfAttorney
  ]);

  const handleSave = useCallback(async (powerOfAttorneyRequest: PowerOfAttorneyRequest) => {
    setModalIsOpen(false);
    await onSave(powerOfAttorneyRequest);
  }, [
    onSave
  ]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Power of Attorney
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Name</th>
            <th>Firm Name</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{powerOfAttorney.name}&nbsp;</td>
            <td>{powerOfAttorney.firmName}&nbsp;</td>
            <td>{powerOfAttorney.fullDisplay}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Power of Attorney
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={powerOfAttorneySchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormikInput autoFocus
                             name="name"
                             labelText="Name"/>
              </Col>
              <Col sm="6">
                <FormikInput name="firmName"
                             labelText="Firm Name"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="address"
                             labelText="Address"/>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormikInput name="city"
                             labelText="City"/>
              </Col>
              <Col sm="3">
                <FormikSelect name="state"
                              labelText="State">
                  <option value="">Select</option>
                  {Object.keys(UsState).map(state =>
                    <option value={state} key={state}>{state}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="3">
                <FormikInput name="zip"
                             labelText="Zip"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default PowerOfAttorneyCard;