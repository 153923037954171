export const isNumber = (value: any) => {
  if (value === '' || value === undefined || value == null) {
    return false;
  } else if (!isNaN(Number(value))) {
    return true;
  }
};

export const zeroIfNull = (value: number | string | null | undefined) => {
  if (value === null || value === undefined || value === '' || isNaN(Number(value))) {
    return 0;
  } else if (typeof value === 'string') {
    return Number(value);
  } else {
    return value;
  }
};