import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik} from 'formik';

import {
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  downloadUtils,
  FormikFileInput,
  useAlerts,
  useUserContext
} from '@reasoncorp/kyber-js';

import {documentSchema} from '../../schema';
import {DocumentResponse} from '../../types';
import {DocumentUploadRequest} from '../../types/request';
import {documentApi, parcelApi} from '../../api';
import * as messages from '../../messages';
import {formatDateTime} from '../../util';

type Props = {
  documents: DocumentResponse[]
  onSuccess: () => void
  parcelId: number
  className?: string
}

const DocumentsCard = ({
                         documents,
                         onSuccess,
                         parcelId,
                         className
                       }: Props) => {
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentResponse | undefined>(undefined);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {permissions} = useUserContext();

  const cardClassName = useMemo(() => {
    return className !== undefined ? `${className} hide-print` : 'hide-print';
  }, [
    className
  ]);

  const handleSave = useCallback(async (documentUploadRequest: DocumentUploadRequest) => {
    try {
      const fileData = new FormData();
      fileData.append('file', documentUploadRequest.file as File);
      await parcelApi.uploadDocument(parcelId, fileData);
      showSuccessAlert(messages.DOCUMENT_UPLOAD_SUCCESSFUL);
      setUploadModalIsOpen(false);
      await onSuccess();
    } catch (e) {
      setUploadModalIsOpen(false);
      showErrorAlert(messages.DOCUMENT_UPLOAD_FAILURE);
    }
  }, [
    showSuccessAlert,
    showErrorAlert,
    onSuccess,
    parcelId
  ]);

  const handleDelete = useCallback(async () => {
    try {
      if (selectedDocument && selectedDocument.deleteLink) {
        await documentApi.deleteDocument(selectedDocument.deleteLink);
        await onSuccess();
        setDeleteModalIsOpen(false);
        showSuccessAlert(messages.DOCUMENT_DELETE_SUCCESSFUL);
        setSelectedDocument(undefined);
      }
    } catch (e) {
      setDeleteModalIsOpen(false);
      showErrorAlert(messages.DOCUMENT_DELETE_FAILURE);
    }
  }, [
    onSuccess,
    showErrorAlert,
    showSuccessAlert,
    selectedDocument
  ]);

  const handleDownload = useCallback(async (path: string) => {
    try {
      await downloadUtils.downloadFile(documentApi.getSignedUrl(path));
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
    }
  }, [
    showErrorAlert
  ]);

  const initialValues: DocumentUploadRequest = useMemo(() => ({
    file: null
  }), []);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Date', sortKey: 'createdAt', className: 'w-15 text-center'},
      {title: 'File Name', sortKey: 'displayFilename', className: 'w-25'},
      {title: 'User', sortKey: 'username', className: 'w-30'},
      {title: 'Download', sortDisabled: true, className: 'w-15 text-center'},
      {title: 'Delete File', sortDisabled: true, className: 'w-15 text-center', hide: !permissions.hasWriteAccess}
    ],
    items: documents,
    initialSort: {sortKey: 'createdAt', direction: 'desc' as 'desc'},
    noResultsMessage: 'No documents uploaded.',
    renderRow: (document: DocumentResponse) => {
      return <tr key={document.id}>
        <td className="text-center align-middle">{formatDateTime(document.createdAt)}</td>
        <td className="align-middle">{document.displayFilename}</td>
        <td className="align-middle">{document.username}</td>
        <td className="text-center align-middle">
          <ButtonIcon icon="paperclip"
                      className="text-success"
                      onClick={async () => await handleDownload(document.downloadLink)}
                      ariaLabel={`download document ${document.displayFilename}`}
                      title={`download document ${document.displayFilename}`}/>
        </td>
        {permissions.hasWriteAccess && <td className="text-center align-middle">
          {document.deleteLink && <ButtonIcon icon="trash"
                                              className="text-danger"
                                              onClick={() => {
                                                setSelectedDocument(document);
                                                setDeleteModalIsOpen(true);
                                              }}
                                              ariaLabel={`delete document ${document.filename}`}
                                              title={`delete document ${document.filename}`}/>
          }
        </td>}
      </tr>;
    }
  }), [
    documents,
    handleDownload,
    permissions
  ]);

  return <>
    <Card className={cardClassName}>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Documents
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setUploadModalIsOpen(true)}
                                                   size="sm">
              Upload Document
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <CustomTable {...tableProps} />
    </Card>
    <Modal isOpen={uploadModalIsOpen}
           toggle={() => setUploadModalIsOpen(!uploadModalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setUploadModalIsOpen(!uploadModalIsOpen)}
                   tag="h2"
                   className="h5">
        Upload Document
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={documentSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col>
                <FormikFileInput name="file"
                                 labelText="Upload Document"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Upload
            </Button>
            <Button color="secondary"
                    onClick={() => setUploadModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>

    <ConfirmationModal title="Delete Document"
                       cancelCallback={() => setDeleteModalIsOpen(false)}
                       confirmCallback={async () => await handleDelete()}
                       size="lg"
                       confirmButtonText="Yes"
                       cancelButtonText="No"
                       isOpen={deleteModalIsOpen}>
      Are you sure you wish to delete <span className="text-danger">{selectedDocument?.filename}</span>?
    </ConfirmationModal>
  </>;
};

export default DocumentsCard;