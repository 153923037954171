import {BonaFideLetterBatchDto, BonaFideLetterBatchLite} from '../types/bonaFide';
import {BonaFideLetterBatchRequest} from '../types/request/bonaFide';
import {proServApi} from './apiUtils';

export const findBatches = (): Promise<BonaFideLetterBatchLite[]> => proServApi.getWithJsonResponse(
  `/bona-fides/letters/batches`
);

export const findBatch = (id: number): Promise<BonaFideLetterBatchDto> => proServApi.getWithJsonResponse(
  `/bona-fides/letters/batches/${id}`
);

export const createBatch = (bonaFideLetterBatchRequest: BonaFideLetterBatchRequest): Promise<BonaFideLetterBatchDto> => proServApi.postWithJsonResponse(
  `/bona-fides/letters/batches`,
  {
    body: JSON.stringify(bonaFideLetterBatchRequest)
  }
);

export const getSignedUrl = (id: number): Promise<string> => proServApi.getWithTextResponse(
  `/bona-fides/letters/${id}/signed-url`
);

export const getBatchSignedUrl = (id: number): Promise<string> => proServApi.getWithTextResponse(
  `/bona-fides/letters/batches/${id}/signed-url`
);

export const deleteLetter = (id: number): Promise<string> => proServApi.deleteWithTextResponse(
  `/bona-fides/letters/${id}`
);

export const deleteBatch = (id: number): Promise<string> => proServApi.deleteWithTextResponse(
  `/bona-fides/letters/batches/${id}`
);

export const recreateBatch = (id: number): Promise<BonaFideLetterBatchDto> => proServApi.putWithJsonResponse(
  `/bona-fides/letters/batches/${id}`
);