import {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik} from 'formik';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {AppealResponse} from '../../types/appeal';
import {AppealStatusRequest} from '../../types/request/appeal';
import {CollectionContext} from '../../contexts';
import {appealStatusSchema} from '../../schema/appeal';

type Props = {
  appeal: AppealResponse
  onSave: (appealStatusRequest: AppealStatusRequest) => void
}

type Values = {
  status: string
}

const AppealStatusButton = ({
                              appeal,
                              onSave
                            }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {collections} = useContext(CollectionContext);

  const handleSave = useCallback(async (values: Values) => {
    const appealStatusRequest = {
      ...values
    } as AppealStatusRequest;

    await onSave(appealStatusRequest);
    setModalIsOpen(false);
  }, [
    onSave
  ]);

  const initialValues = useMemo(() => ({
    status: appeal.status
  }), [
    appeal
  ]);

  return <>
    <Button color="primary"
            onClick={() => setModalIsOpen(true)}
            className="mr-2">
      Edit Status
    </Button>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="md">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Appeal Status
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={appealStatusSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col>
                <FormikSelect name="status"
                              labelText="Status">
                  <option value="">Select</option>
                  {collections?.appealStatuses && Object.keys(collections?.appealStatuses).sort().map(value =>
                    <option value={value} key={value}>{collections.appealStatuses[value]}</option>)
                  }
                </FormikSelect>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default AppealStatusButton;