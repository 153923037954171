import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {StatefulButton} from '@reasoncorp/kyber-js';

import {BonaFideLetterBatchDto} from '../../types/bonaFide';

type Props = {
  bonaFideLetterBatch: BonaFideLetterBatchDto
  isOpen: boolean
  processing: boolean
  onDelete: (id: number) => void
  onCancel: () => void
}

const BonaFideLetterBatchDeleteModal = ({
                                          bonaFideLetterBatch,
                                          isOpen,
                                          processing,
                                          onDelete,
                                          onCancel
                                        }: Props) => {
  return <Modal isOpen={isOpen}
                toggle={() => onCancel()}
                size="md"
                returnFocusAfterClose={true}
                autoFocus={false}>
    <ModalHeader>Delete Letter Batch</ModalHeader>
    <ModalBody>
      Are you sure you wish to delete letter batch <span className="text-danger">{bonaFideLetterBatch.fileNumber}</span>?
    </ModalBody>
    <ModalFooter>
      <StatefulButton color="success"
                      className="mr-1"
                      disabled={processing}
                      onClick={() => onDelete(bonaFideLetterBatch.id)}
                      aria-label="Delete letter batch button"
                      activeState={processing ? 'deleting' : 'default'}
                      states={[
                        {name: 'deleting', text: 'Deleting Letter Batch', icon: 'spinner', spinIcon: true},
                        {name: 'default', text: 'Yes'}
                      ]}/>
      <Button color="secondary"
              onClick={() => onCancel()}
              disabled={processing}>
        No
      </Button>
    </ModalFooter>
  </Modal>;
};

export default BonaFideLetterBatchDeleteModal;