import {ChangeEvent, useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik, FormikProps} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ButtonIcon, FormikDatePicker, FormikInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {AppealResponse, InformalHearing} from '../../types/appeal';
import {InformalHearingRequest} from '../../types/request/appeal';
import {YearDeniedReviewRequest} from '../../types/request';
import {formatDate, formatPercent, getAvailableDenialYears, isNumber} from '../../util';
import {informalHearingSchema} from '../../schema/appeal';
import {CollectionContext} from '../../contexts';

type Props = {
  informalHearing: InformalHearing
  appeal: AppealResponse
  onSave: (informalHearingRequest: InformalHearingRequest) => void
}

type Values = InformalHearingRequest & {
  uniqueYearsErrorMessage?: string // can be added via validation schema
}

const AppealInformalHearingCard = ({
                                     informalHearing,
                                     appeal,
                                     onSave
                                   }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasRemovedYear, setHasRemovedYear] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (informalHearingRequest: InformalHearingRequest) => {
    await onSave(informalHearingRequest);
    setModalIsOpen(false);
  }, [
    onSave
  ]);

  const sentToHearingsOn = useMemo(() => {
    const sentToHearingsOn = informalHearing.sentToHearingsOn;
    if ((sentToHearingsOn === undefined || sentToHearingsOn === null) && appeal.timely) {
      return appeal.submittedOn;
    } else if ((sentToHearingsOn === undefined || sentToHearingsOn === null) && !appeal.timely) {
      return appeal.noticeSentOn;
    } else {
      return sentToHearingsOn;
    }
  }, [
    informalHearing,
    appeal
  ]);

  const initialValues: Values = useMemo(() => ({
    docketNumber: informalHearing.docketNumber,
    hearingOn: informalHearing.hearingOn,
    sentToHearingsOn,
    determinationOn: informalHearing.determinationOn,
    decision: informalHearing.decision,
    refereeName: informalHearing.refereeName,
    analystId: informalHearing.analystId,
    yearDeniedReviews: informalHearing.yearDeniedReviews
  }), [
    informalHearing,
    sentToHearingsOn
  ]);

  const handleAddYear = useCallback((formikProps: FormikProps<InformalHearingRequest>) => {
    const newYear = {
      year: '',
      decision: '',
      season: '',
      deniedPercent: ''
    } as YearDeniedReviewRequest;

    formikProps.setFieldValue('yearDeniedReviews',
      [formikProps.values.yearDeniedReviews, ...[newYear]].flat()
    );
  }, []);

  const handleRemoveYear = useCallback((formikProps: FormikProps<InformalHearingRequest>, index: number) => {
    const yearDeniedReviews = [...formikProps.values.yearDeniedReviews];
    yearDeniedReviews.splice(index, 1);
    formikProps.setFieldValue('yearDeniedReviews', yearDeniedReviews);
    setHasRemovedYear(true);
  }, []);

  const handleDecisionChange = useCallback((formikProps: FormikProps<Values>, index: number) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === 'VALID') {
        formikProps.setFieldValue(`yearDeniedReviews[${index}].deniedPercent`, 0);
      } else if (e.target.value === 'DENIED') {
        formikProps.setFieldValue(`yearDeniedReviews[${index}].deniedPercent`, '');
      }
    }, []);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Informal Conference Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-20">Date Referred to Hearings</th>
            <th className="w-20">Days from Appeal to Referral</th>
            <th className="w-20">Docket Number</th>
            <th>Date of Final Determination</th>
            <th>Days to Determination</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(informalHearing.sentToHearingsOn)}&nbsp;</td>
            <td>{informalHearing.daysFromAppealToReferral}&nbsp;</td>
            <td>{informalHearing.docketNumber}&nbsp;</td>
            <td>{formatDate(informalHearing.determinationOn)}&nbsp;</td>
            <td>{informalHearing.daysToFinalDetermination}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
      <Table responsive bordered>
        <thead>
          <tr className="border-top-3">
            <th className="w-20">Final Determination</th>
            <th className="w-20">Hearing Date</th>
            <th className="w-20">Representing Analyst</th>
            <th>Referee</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{informalHearing.decision}&nbsp;</td>
            <td>{formatDate(informalHearing.hearingOn)}&nbsp;</td>
            <td>{informalHearing.analystName}&nbsp;</td>
            <td>{informalHearing.refereeName}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
      <Table responsive bordered>
        <thead>
          <tr className="border-top-3">
            <th className="w-20">Year</th>
            <th className="w-20">Summer or Winter</th>
            <th className="w-20">Valid or Denied</th>
            <th className="w-20">Percent Denied</th>
            <th className="w-20">Percent Allowed</th>
          </tr>
        </thead>
        <tbody>
          {informalHearing.yearDeniedReviews.length === 0 && <tr>
            <td colSpan={5}>No year denied reviews.</td>
          </tr>}
          {informalHearing.yearDeniedReviews.map(yearDeniedReview => <tr key={yearDeniedReview.id}>
              <th scope="row">{yearDeniedReview.year}</th>
              <td>{yearDeniedReview.season}</td>
              <td>{yearDeniedReview.decision}</td>
              <td>{formatPercent(yearDeniedReview.deniedPercent)}</td>
              <td>{formatPercent(yearDeniedReview.allowedPercent)}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           returnFocusAfterClose={true}
           size="xl">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Informal Conference Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={informalHearingSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="3">
                <FormikDatePicker name="sentToHearingsOn"
                                  ariaRequired={true}
                                  labelText="Date Referred to Hearings"/>
              </Col>
              <Col sm="3">
                <FormikInput name="docketNumber"
                             labelText="Docket Number"/>
              </Col>
              <Col sm="3">
                <FormikDatePicker name="determinationOn"
                                  labelText="Date of Final Determination"/>
              </Col>
              <Col sm="3">
                <FormikSelect name="decision"
                              labelText="Final Determination">
                  <option value="">Select</option>
                  {collections?.informalHearingDecisions.map(informalHearingDecision =>
                    <option key={informalHearingDecision} value={informalHearingDecision.toUpperCase()}>{informalHearingDecision}</option>)}
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col sm="3">
                <FormikDatePicker name="hearingOn"
                                  labelText="Hearing Date"/>
              </Col>
              <Col sm="3">
                <FormikSelect name="analystId"
                              labelText="Representing Analyst">
                  <option value="">Select</option>
                  {collections?.analysts.map(analyst =>
                    <option key={analyst.id} value={analyst.id}>{analyst.fullName}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="3">
                <FormikSelect name="refereeName"
                              labelText="Referee">
                  <option value="">Select</option>
                  {collections?.referees.map(referee =>
                    <option key={referee} value={referee}>{referee}</option>)}
                </FormikSelect>
              </Col>
            </Row>
          </ModalBody>
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th className="text-center w-10">Year</th>
                <th className="text-center w-25">Summer or Winter</th>
                <th className="w-25">Valid or Denied</th>
                <th className="text-right w-20">Percent Denied</th>
                <th className="text-right w-20">Percent Allowed</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {formikProps.values.yearDeniedReviews.length === 0 && <tr>
                <td colSpan={5}>No year denied reviews.</td>
              </tr>}
              {formikProps.values.yearDeniedReviews.map((_, index) => <>
                  <tr key={`year-denied-review-${index}`}>
                    <th scope="row" className="align-middle text-center">
                      <FormikSelect autoFocus={index === 0}
                                    name={`yearDeniedReviews[${index}].year`}
                                    formGroupClass="mb-0"
                                    ariaLabel="Year">
                        <option value="">Select</option>
                        {getAvailableDenialYears.map(year => <option value={year} key={year}>{year}</option>)}
                      </FormikSelect>
                    </th>
                    <td className="align-middle text-center">
                      <FormikSelect name={`yearDeniedReviews[${index}].season`}
                                    formGroupClass="mb-0"
                                    ariaLabel="Summer or Winter">
                        <option value="">Select</option>
                        <option value="SUMMER" key="SUMMER">SUMMER</option>
                        <option value="WINTER" key="WINTER">WINTER</option>
                      </FormikSelect>
                    </td>
                    <td>
                      <FormikSelect name={`yearDeniedReviews[${index}].decision`}
                                    formGroupClass="mb-0"
                                    onChange={handleDecisionChange(formikProps, index)}
                                    ariaLabel="Decision">
                        <option value="">Select</option>
                        <option value="VALID" key="VALID">Valid</option>
                        <option value="DENIED" key="DENIED">Denied</option>
                      </FormikSelect>
                    </td>
                    <td>
                      <FormikInput name={`yearDeniedReviews[${index}].deniedPercent`}
                                   formGroupClass="mb-0"
                                   className="text-right"
                                   disabled={formikProps.values.yearDeniedReviews[index].decision === 'VALID'}
                                   disableFloatingLabel={true}
                                   ariaLabel="Percent Denied"/>
                    </td>
                    <td className="text-right pt-3">
                      {isNumber(formikProps.values.yearDeniedReviews[index].deniedPercent) &&
                        formatPercent(100 - Number(formikProps.values.yearDeniedReviews[index].deniedPercent))}
                    </td>
                    <td>
                      <ButtonIcon icon="trash"
                                  onClick={() => handleRemoveYear(formikProps, index)}
                                  className="text-danger"
                                  title={`Delete Year ${formikProps.values.yearDeniedReviews[index].year}`}
                                  ariaLabel={`Delete Year ${formikProps.values.yearDeniedReviews[index].year}`}/>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {formikProps.errors.uniqueYearsErrorMessage &&
            <Row className="ml-1 mr-1 pt-2 pb-2">
              <Col className="text-danger font-weight-bold">
                {formikProps.errors.uniqueYearsErrorMessage}
              </Col>
            </Row>
          }
          <ModalFooter className="pr-0 pl-0">
            <Row className="w-100 d-flex m-0">
              <Col className="align-self-center" sm="4">
                <Button size="sm"
                        outline
                        disabled={formikProps.values.yearDeniedReviews.length >= 8}
                        onClick={() => handleAddYear(formikProps)}
                        color="primary">
                  <FontAwesomeIcon icon="plus-circle" className="mr-2"/>
                  Add Year
                </Button>
              </Col>
              <Col className="d-flex justify-content-end" sm="8">
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || (!hasRemovedYear && !formikProps.dirty) || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};


export default AppealInformalHearingCard;