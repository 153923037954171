import {sum} from 'lodash';
import Decimal from 'decimal.js';
import {differenceInCalendarMonths, parse} from 'date-fns';

import {BonaFideDenialYear} from '../types/bonaFide';
import {isNumber, zeroIfNull} from './mathUtils';

const INTEREST_RATE_PER_MONTH = new Decimal(0.0125);

export const getSummerLastDueDate = (year?: number | null) => {
  return !year ? null : new Date(year, 8, 1);
};

export const getWinterLastDueDate = (year?: number | null) => {
  return !year ? null : new Date(year + 1, 1, 1);
};

export const getNumberOfMonthsBetweenDueDateAndBilledOn = (values: BonaFideDenialYear) => {
  const {billedOn, year, taxLevySeason} = values;

  if (!billedOn || !year || !taxLevySeason) {
    return null;
  }

  const billedOnAsDate = billedOn instanceof Date ? billedOn : parse(billedOn, 'yyyy-MM-dd', new Date());

  if (taxLevySeason === 'SUMMER') {
    return Math.abs(differenceInCalendarMonths(billedOnAsDate, getSummerLastDueDate(Number(year))!));
  } else if (taxLevySeason === 'WINTER') {
    return Math.abs(differenceInCalendarMonths(billedOnAsDate, getWinterLastDueDate(Number(year))!));
  } else {
    return null;
  }
};

export const calculateInterestRate = (values: BonaFideDenialYear) => {
  const numberOfMonthsBetweenDueDueAndBilledOn = getNumberOfMonthsBetweenDueDateAndBilledOn(values);
  if (numberOfMonthsBetweenDueDueAndBilledOn == null) {
    return null;
  }

  return new Decimal(numberOfMonthsBetweenDueDueAndBilledOn)
    .mul(INTEREST_RATE_PER_MONTH);
};

export const displayInterestDue = (values: BonaFideDenialYear): number => {
  return Number(
    new Decimal(calculateInterestDue(values))
      .round()
      .toString()
  );
};

export const calculateInterestDue = (values: BonaFideDenialYear): number => {
  const taxDue = calculateTaxDue(values);
  const interestRate = calculateInterestRate(values);

  if (values.taxLevySeason && values.taxLevySeason === 'COMBINED') {
    return calculateInterestDueCombined(values);
  } else if (!taxDue || !interestRate || !values.taxLevySeason) {
    return 0;
  } else {
    return Number(
      new Decimal(taxDue)
        .mul(interestRate)
        .toString()
    );
  }
};

export const calculateInterestDueCombined = (values: BonaFideDenialYear) => {
  if (!values.millageRate || !isNumber(values.millageRate)) {
    return 0;
  }

  const halfMillageRate = Number(new Decimal(values.millageRate)
    .div(2)
    .toString());

  const interestDueSummer = calculateInterestDue({
    ...values,
    millageRate: halfMillageRate,
    taxLevySeason: 'SUMMER'
  }) || 0;

  const interestDueWinter = calculateInterestDue({
    ...values,
    millageRate: halfMillageRate,
    taxLevySeason: 'WINTER'
  }) || 0;

  return interestDueSummer + interestDueWinter;
};

export const displayTaxDue = (values: BonaFideDenialYear) => {
  return Number(
    new Decimal(calculateTaxDue(values))
      .round()
      .toString());
};

export const calculateTaxDue = ({
                                  millageRate,
                                  taxableValue
                                }: BonaFideDenialYear) => {
  if (!taxableValue || !millageRate || !isNumber(millageRate)) {
    return 0;
  } else {
    return Number(
      new Decimal(taxableValue)
        .mul(millageRate)
        .div(1000)
        .toString()
    );
  }
};

export const calculateDenialYearTotal = (values: BonaFideDenialYear) => {
  const total = sum([
    zeroIfNull(calculateTaxDue(values)),
    zeroIfNull(calculateInterestDue(values)),
    zeroIfNull(values.exemptionPenaltyAmount),
    zeroIfNull(values.billAdjustmentAmount)
  ]);

  return Number(
    new Decimal(total)
      .round()
      .toString()
  );
};